import React, { useState, useContext, useCallback, useEffect, useReducer, useRef } from 'react';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import {
  Col,
  Row,
  Modal,
  ButtonGroup,
  Button,
  Card
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faPauseCircle, faPlayCircle, faDownload } from '@fortawesome/free-solid-svg-icons';
/** Custom Imports */
import Layout from '../../components/Layout';
import Loader from '../../components/Loader';
import ReportGoal from '../../components/ReportGoal';
/** Context */
import { UserContext } from '../../context/UserContext';
/** Styles */
import styles from './styles.module.scss';

const UserReport = () => {
  const [ loading, setLoading ] = useState(true);
  const [ error, setError ] = useState('');
  const [ show, toggle ] = useReducer( state => !state, true );
  const [ reportData, setReportData ] = useState([]);
  const [ userContext ] = useContext(UserContext);
  const [ showComplete, toggleShowComplete ] = useState(false);
  const [ showProgress, toggleShowProg ] = useState(false);
  const [ showHold, toggleShowHold ] = useState(false);
  const printRef = useRef();
  const genericErrorMessage = "Something went wrong. Please try again later.";

  const fetchReportData = useCallback(() => {
    if ( userContext.token ) {
      fetch("/api/goal/generate", {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ userContext.token }`,
        },
      })
      .then( async response => {
        if ( response.ok || response.status === 200) {
          const data = await response.json();
          setReportData(data);
        } else {
          toggle();
          setError(response?.message || genericErrorMessage);
        }
      })
      .catch(error => {
        toggle();
        setError(error?.message || genericErrorMessage);
      })
      .finally(() => {
        setLoading(false);
      });
    }
  }, [userContext.token]);

  useEffect(() => {
    fetchReportData();
  }, [fetchReportData]);

  const generateReport = async (e) => {
    e.preventDefault();
    const element = printRef.current;
    const canvas = await html2canvas(element);
    const imgData = canvas.toDataURL('image/png');
    const imgWidth = 190;
    const pageHeight = 290;
    const imgHeight = ( canvas.height * imgWidth ) / canvas.width;
    let heightRemaining = imgHeight;
    const pdf = new jsPDF({
      orientation: 'p',
      unit: 'mm',
      format: 'a4',
      compress: true
    });
    let position = 0;
    pdf.addImage( imgData, 'PNG', 10, 0, imgWidth, imgHeight + 20);
    heightRemaining -= pageHeight;
    while ( heightRemaining >= 0 ) {
      position = heightRemaining - imgHeight;
      pdf.addPage();
      pdf.addImage( imgData, 'PNG', 10, position, imgWidth, imgHeight + 20);
      heightRemaining -= pageHeight;
    }
    pdf.save('adaptability_goals_report.pdf');
  };

  return (
    <Layout>
      <div className={styles.userReport + ` create-report`}>
        {
          error &&
          <Modal
            show={show}
            onHide={ toggle }
            aria-labelledby="error-modal"
            centered
          >
            <Modal.Header>
              <Modal.Title>
                Error
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              { error }
            </Modal.Body>
          </Modal>
        }
        <Row className="content hero mb-4">
          <Col>
            <FontAwesomeIcon icon={ faDownload } size="5x" className="text-primary float-right" aria-hidden="true" />
            <h1 className="mb-4">
              <span className="display-4 text-gray-600 text-uppercase">CREATE REPORT</span>
            </h1>
            <p className="lead">
              Your data is never shared unless you choose to do so. If you would like to download your goals for your own records or 
              {' '}to share with your ADAPT team, use the form below to create your downloadable report.
              {' '}You may choose to export all goals or only goals of a certain status.
            </p>
          </Col>
        </Row>
        <Row className="content mb-4">
          <Col>
            <Card className="border-bottom-primary mb-4">
              <Card.Header className="bg-primary py-3">
                <h1 className="h6 m-0 font-weight-bold text-uppercase text-white">
                  Build Goal Report
                </h1>
              </Card.Header>
              <Card.Body>
                <h2 className="h5 text-gray-800 font-weight-bold">Goal Filters</h2>
                <p className="h6 font-weight-normal form-text mb-4">
                  Choose the status type(s) for the goals you would like to include in your report.
                  {' '}Toggle the buttons to make your selection(s).
                </p>
                <ButtonGroup aria-label="Report Filters" className="d-block mx-auto">
                  <Button
                    variant="info"
                    className="btn-lg mr-2"
                    onClick={() => toggleShowProg(!showProgress)}
                  >
                    Active Goals
                  </Button>                     
                  <Button
                    variant="info"
                    className="btn-lg mr-2"
                    onClick={() => toggleShowHold(!showHold)}
                  >
                    Goals On Hold
                  </Button>
                  <Button
                    variant="info"
                    className="btn-lg"
                    onClick={() => toggleShowComplete(!showComplete)}
                  >
                    Completed Goals
                  </Button>
                </ButtonGroup>

                <div className="d-flex justify-content-end mt-4">
                  <Button
                    onClick={e => generateReport(e)}
                    className="btn-lg flex-fill flex-md-grow-0"
                  >
                    <FontAwesomeIcon icon={faDownload} className="text-white-50" aria-hidden="true" />
                    {' '}
                    Download Report
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="content mb-4">
          <Col>
            <Card className="border-bottom-secondary mb-4">
              <Card.Header className="bg-secondary py-3">
                <h1 className="h6 m-0 font-weight-bold text-uppercase text-white">
                  Goal Report Preview
                </h1>
              </Card.Header>
              <Card.Body>
              { loading ? (
                  <Loader />
                ) : (
                  <span ref={printRef} className={styles.report_container}>
                    <Row className="mx-4">
                      <Col>
                        <h1 class="mb-4">ADAPTability Report</h1>
                        <p><strong>Total Goals:</strong> { reportData.complete.length + reportData.progress.length + reportData.hold.length }</p>
                      </Col>
                    </Row>
                    { showProgress &&
                      <Row className="goals mb-4 mx-4">
                        <Col>
                          <Card id="report-in-progress">
                            <Card.Header className="bg-primary text-uppercase text-white">
                              <FontAwesomeIcon icon={faPlayCircle} className="text-white-50" aria-hidden="true" />
                              {' '}
                              Active Goals
                            </Card.Header>
                            <Card.Body>
                              {
                                reportData.progress.map(function (goal) {
                                  return <ReportGoal key={goal._id} goal={goal} />
                                })
                              }
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    }
                    { showHold &&
                      <Row className="goals mb-4 mx-4">
                        <Col>
                          <Card id="report-on-hold" >
                            <Card.Header className="bg-secondary text-uppercase text-white">
                              <FontAwesomeIcon icon={faPauseCircle} className="text-white-50" aria-hidden="true" />
                              {' '}
                              Goals on Hold
                            </Card.Header>
                            <Card.Body>
                              {
                                reportData.hold.map(function (goal) {
                                  return <ReportGoal key={goal._id} goal={goal} />
                                })
                              }
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    }
                    { showComplete && 
                      <Row className="goals mb-4 mx-4">
                        <Col>
                          <Card id="report-complete-goals">
                            <Card.Header className="bg-success text-uppercase text-white">
                              <FontAwesomeIcon icon={faCheckCircle} className="bg-success text-uppercase" aria-hidden="true" />
                              {' '}
                              Completed Goals
                            </Card.Header>
                            <Card.Body>
                              {
                                reportData.complete.map(function (goal) {
                                  return <ReportGoal key={goal._id} goal={goal} />
                                })
                              }
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    }
                  </span>
                )
              }
                <div className="d-flex justify-content-end mt-4">
                  <Button
                    onClick={e => generateReport(e)}
                    className="btn-lg flex-fill flex-md-grow-0"
                  >
                    <FontAwesomeIcon icon={faDownload} className="text-white-50" aria-hidden="true" />
                    {' '}
                    Download Report
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default UserReport;