import React from 'react';
import { 
  Spinner,
  Row,
  Col
 } from 'react-bootstrap';

const Loader = () => {
  return (
    <Row>
      <Col>
        <Spinner animation="border" size="lg" />
      </Col>
    </Row>
  );
};

export default Loader;