/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Button,
  Row,
  Col,
  Card,
  Form,
  Modal
} from 'react-bootstrap';

/** Custom Imports */
import Layout from '../../components/Layout';
/** Context */
import { UserContext } from '../../context/UserContext';
/** Assets */

/** Styles */
// import styles from './styles.module.scss';

const ResetPassword = (props) => {
  const [ password, setPassword ] = useState('');
  const [ success, setSuccess ] = useState(false);
  const [ confirmPass, setConfirmPass ] = useState('');
  const [ show, setShow ] = useState(false);
  const [ modalTitle, setModalTitle ] = useState('');
  const [ modalMessage, setModalMessage ] = useState('');
  const [ userContext, setUserContext ] = useContext(UserContext);
  const history = useHistory();
  const paramVals = useParams('');
  const resetToken = paramVals.token;
  const userGuid = paramVals.uid;
  const genericErrorMessage = "Something went wrong. Please trya gain later.";

  const redirectLoggedIn = () => {
    return history.push("/profile");
  };

  const toggleModal = () => {
    setShow(false);
    if ( success ) {
      return setTimeout(() => {
        history.push('/login');
      }, 1000);
    }
  };

  useEffect(() => {
    if ( userContext.details || userContext.token || !resetToken ) {
      redirectLoggedIn();
    }
  });

  const formSubmitHandler = e => {
    e.preventDefault();
    if ( resetToken ) {
      if ( password === confirmPass ) {
        fetch(`/api/user/reset-password/${ userGuid }/${ resetToken }`, {
          method: 'POST',
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            password: password
          })
        })
        .then( async response => {
          if ( !response.ok ) {
            setShow(true);
            setModalTitle('Error');
            setModalMessage(response.message ? response.message : genericErrorMessage);
          } else {
            setShow(true);
            setSuccess(true);
            setModalTitle('Success');
            setModalMessage('Success! Please click or tap outside of this message to continue.')
          }
        })
        .catch(error => {
          setShow(true);
          setModalTitle('Error');
          setModalMessage(`An error occurred: ${ error }`);
        });
      } else {
        setShow(true);
        setModalTitle('Error');
        setModalMessage("Password and Confirm Password must match");
      }
    } else {
      setShow(true);
      setModalTitle('Error');
      setModalMessage('Could not retrieve your reset token');
    }
  };

  return(
    <Layout>
      <div className="reset-password">
        { 
          modalMessage &&
          <Modal
            show={show}
            onHide={ toggleModal }
            aria-labelledby="error-modal"
            centered
          >
            <Modal.Header>
              <Modal.Title>
                { modalTitle }
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              { modalMessage }
            </Modal.Body>
          </Modal>
        }
        <Row className="justify-content-center">
          <Col sm={12} md={6}>
            <Card className="border-bottom-primary mx-4 mx-md-0">
              <Card.Header className="bg-primary py-3">
                <h1 className="h6 m-0 font-weight-bold text-uppercase text-white text-center">
                  Reset Your Password
                </h1>
              </Card.Header>
              <Card.Body className="mb-4">
                <Card.Text className="text-center">Enter and confirm your new password below.</Card.Text>
                <Form onSubmit={formSubmitHandler}>
                  <Row class="form-row">
                    <Col>
                      <Form.Group class="form-group">
                        <Form.Label>New Password</Form.Label>
                        <Form.Control
                          id="pass"
                          type="password"
                          onChange={e => setPassword(e.target.value)}
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row class="form-row mb-4">
                    <Col>
                      <Form.Group class="form-group">
                        <Form.Label>Confirm New Password</Form.Label>
                        <Form.Control
                          id="confirmPass"
                          type="password"
                          onChange={e => setConfirmPass(e.target.value)}
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row class="form-row">
                    <Col>
                      <Button
                        variant="primary"
                        size="lg"
                        type="submit"
                        className="btn-block"
                      >
                        Reset Password
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
              <Card.Footer className="pt-4">
                <p className="text-center" ><Card.Link href="/login">Remembered your password? Log in!</Card.Link></p>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default ResetPassword;