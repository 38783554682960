import { useRef, useContext, useState, useEffect } from "react";
import { Button, Col, Form, Modal, Row, Card } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import ReCAPTCHA from "react-google-recaptcha";
/** Custom Imports */
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
/** Context */
import { UserContext } from "../../context/UserContext";
/** Styles */
import styles from "./styles.module.scss";

const Register = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [show, setShow] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [notificationType, setNotificationType] = useState("none");
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  // eslint-disable-next-line
  const [userContext, setUserContext] = useContext(UserContext);
  const history = useHistory();
  const recaptchaRef = useRef();

  const toggleModal = () => {
    setShow(false);
    if (modalTitle.toLowerCase() === "success") {
      return setTimeout(() => {
        history.push("/dashboard");
      }, 1000);
    }
  };
  const SITEKEY = process.env.REACT_APP_CAPTCHA_SITE_KEY;

  const cancelFormSubmit = (e) => {
    e.preventDefault();
    history.push("/");
  };

  const formSubmitHandler = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setModalMessage("");
    const captchaToken = await recaptchaRef.current.executeAsync();
    const genericErrorMessage = "Something went wrong. Please try again later.";
    if (password === confirmPass) {
      fetch("/api/user/signup", {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          firstName,
          lastName,
          username: email,
          password,
          notificationType,
          acceptedTerms,
          captchaToken,
        }),
      })
        .then(async (response) => {
          if (!response.ok) {
            setShow(true);
            setModalTitle("Error");
            if (response.status === 400) {
              setModalMessage("Please ensure all fields have been filled in.");
            } else if (response.status === 401) {
              setModalMessage("Invalid email or password.");
            } else if (response.status === 404) {
              const data = await response.json();
              if (data.message)
                setModalMessage(data.message || genericErrorMessage);
            } else if (response.status === 500) {
              const data = await response.json();
              if (data.message)
                setModalMessage(data.message || genericErrorMessage);
            } else if (response.status === 501) {
              const data = await response.json();
              if (data.message)
                setModalMessage(data.message || genericErrorMessage);
            } else {
              setModalMessage(genericErrorMessage);
            }
          } else {
            const data = await response.json();
            setUserContext((oldValues) => {
              return { ...oldValues, token: data.token };
            });
            setShow(true);
            setModalTitle("Success");
            setModalMessage(
              "Successfully registered! Please click or tap anywhere outside of this message to continue."
            );
          }
        })
        .catch((error) => {
          setShow(true);
          setModalTitle("Error");
          setModalMessage(genericErrorMessage);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    } else {
      setShow(true);
      setIsSubmitting(false);
      setModalTitle("Error");
      setModalMessage("Passwords must match");
    }
  };

  useEffect(() => {
    const verifyUser = async () => {
      const res = await fetch("/api/user/refreshToken", {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
      });
      if (res.ok || res.status === 200) {
        history.push("/dashboard");
      }
      setIsLoading(false);
    };

    verifyUser();
  }, [history]);

  return (
    <Layout>
      {isLoading ? (
        <Loader />
      ) : (
        <div className={styles.registration + ` registration`}>
          {modalMessage && (
            <Modal
              show={show}
              onHide={toggleModal}
              aria-labelledby="error-modal"
              centered
            >
              <Modal.Header>
                <Modal.Title>{modalTitle}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{modalMessage}</Modal.Body>
            </Modal>
          )}
          <Row className="content hero mb-4">
            <Col>
              <FontAwesomeIcon
                icon={faPencilAlt}
                size="5x"
                className="text-primary float-right rotate-n-15"
                aria-hidden="true"
              />
              <h1 className="mb-4">
                <span className="display-4 text-gray-600 text-uppercase">
                  Register for
                </span>
                <br />
                <span className="display-2 font-weight-bold text-primary">
                  ADAPTability
                </span>
              </h1>
              <p className="lead">
                Complete the form below to register for ADAPTability. This
                process will guide you through creating an account in order to
                use ADAPTability to enhance your ADAPT treatment. Rest assured
                your account is secure and private. Nothing will ever be shared
                unless you choose to share it.
              </p>
            </Col>
          </Row>
          <Row className="content mb-4">
            <Col>
              <Card className="border-bottom-primary mb-4">
                <Card.Header className="bg-primary py-3">
                  <h1 className="h6 m-0 font-weight-bold text-uppercase text-white">
                    Create an Account
                  </h1>
                </Card.Header>
                <Card.Body>
                  <Form onSubmit={formSubmitHandler}>
                    <Row className="form-row mb-4">
                      <Form.Group
                        as={Col}
                        sm={12}
                        md={6}
                        className="form-group"
                      >
                        <Form.Label>
                          First Name{" "}
                          <span className="text-muted">Optional</span>
                        </Form.Label>
                        <Form.Control
                          id="firstName"
                          placeholder="First Name"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        sm={12}
                        md={6}
                        className="form-group"
                      >
                        <Form.Label>
                          Last Name <span className="text-muted">Optional</span>
                        </Form.Label>
                        <Form.Control
                          id="lastName"
                          placeholder="Last Name"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </Form.Group>
                    </Row>
                    <Row className="form-row mb-4">
                      <Form.Group as={Col} className="form-group">
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control
                          id="email"
                          type="email"
                          placeholder="you@email.com"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </Form.Group>
                    </Row>
                    <Row className="form-row mb-4">
                      <Form.Group
                        as={Col}
                        sm={12}
                        md={6}
                        className="form-group"
                      >
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          id="password"
                          placeholder="Type Password"
                          type="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                        <Form.Text className="pl-1">
                          Password must be a minimum of 6 characters.
                        </Form.Text>
                      </Form.Group>
                      <Form.Group as={Col} className="form-group">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control
                          id="confirmPassword"
                          placeholder="Type Password to Confirm"
                          type="password"
                          value={confirmPass}
                          onChange={(e) => setConfirmPass(e.target.value)}
                          required
                        />
                      </Form.Group>
                    </Row>
                    <Row className="form-row mb-4">
                      <Form.Group as={Col}>
                        <Form.Label class="d-block mb-2">
                          How often would you like to be reminded to check in?
                        </Form.Label>
                        {[
                          { name: "none", readable: "Never" },
                          { name: "weekly", readable: "Weekly" },
                          { name: "monthly", readable: "Monthly" },
                          { name: "twicemonthly", readable: "Twice a Month" },
                        ].map((key) => (
                          <span
                            key={`inline-checkbox-${key.name}`}
                            className="mb-3"
                          >
                            <Form.Check
                              inline
                              label={key.readable}
                              name="notification"
                              type="radio"
                              value={key.name}
                              className="d-inline-flex align-items-center"
                              onChange={(e) =>
                                setNotificationType(e.target.value)
                              }
                              required
                            />
                          </span>
                        ))}
                      </Form.Group>
                    </Row>
                    <Row className="mb-4">
                      <div className="terms mb-4">
                        <h3>Terms of Service</h3>
                        <p class="form-text">
                          You must agree to the terms of service by checking the
                          box at the bottom in order to use this resource.
                        </p>
                        <ol>
                          <li>
                            <strong>Purpose:</strong> This product is intended
                            for use by patients participating in the US Air
                            Force’s Alcohol and Drug Abuse Prevention and
                            Treatment (ADAPT) program. This product is intended
                            to be used to enhance ADAPT treatment. It may be
                            recommended by ADAPT staff, but it is yours to use
                            as you see fit.
                          </li>
                          <li>
                            <strong>Procedures:</strong> While using this
                            program you will have the ability to enter and track
                            your treatment and/or Aftercare goals. You will also
                            receive reminders to track your goals. These can be
                            turned off as you see fit. You can enter as much or
                            as little information on your goals as you see fit.
                            Nothing will be shared with anyone unless you share
                            it.
                          </li>
                          <li>
                            <strong>Duration:</strong> This product is designed
                            for you. As such, you can use it as long as it
                            remains useful for you.
                          </li>
                          <li>
                            <strong>Confidentiality:</strong> All efforts will
                            be made to keep your data private. Data entered on
                            this website will be stored securely. Information
                            that identifies you (e.g., your name, your email,
                            your IP address) will not be available to anyone.
                            Although prevention of data breaches can never be
                            completely guaranteed, servers on which the program
                            is stored conform to the latest security protocols.
                            Please know that materials entered over unsecure
                            networks are vulnerable to interception. In the
                            event of any publication or presentation resulting
                            from the program evaluation, no personally
                            identifiable information will be shared.
                          </li>
                          <li>
                            <strong>Voluntary Participation:</strong>{" "}
                            Utilization of this product is voluntary. You do not
                            have to use this tool. If you choose to participate,
                            you have the right to stop at any time. If you
                            decide to not participate or if you decide to stop
                            at a later date, there will be no penalty or loss of
                            benefits to which you are entitled.
                          </li>
                          <li>
                            <strong>Right to Ask Questions:</strong> Please
                            contact the evaluation team by email at{" "}
                            <a href="mailto:clearinghouse@psu.edu">
                              clearinghouse@psu.edu
                            </a>{" "}
                            if you have questions, complaints, or concerns about
                            this product.
                          </li>
                        </ol>
                      </div>
                      <Form.Group>
                        <Form.Check
                          inline
                          label=" I agree to the terms of service."
                          name="notification"
                          type="checkbox"
                          value={true}
                          className="d-inline-flex align-items-center font-weight-bold"
                          onChange={(e) => setAcceptedTerms(e.target.value)}
                        />
                      </Form.Group>
                    </Row>
                    <Row>
                      <Col>
                        <ReCAPTCHA
                          ref={recaptchaRef}
                          sitekey={SITEKEY}
                          size="invisible"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Col className="d-flex justify-content-end">
                        <Button
                          variant="primary"
                          type="submit"
                          disabled={isSubmitting}
                          className="ml-auto mr-3 btn-lg flex-fill flex-md-grow-0"
                        >
                          {`${isSubmitting ? "Registering" : "Register"}`}
                        </Button>
                        <Button
                          variant="danger"
                          onClick={cancelFormSubmit}
                          className="btn-lg flex-fill flex-md-grow-0"
                        >
                          Cancel
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </Layout>
  );
};

export default Register;
