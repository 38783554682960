import React, { useCallback, useContext, useEffect } from "react";
import { 
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory
} from "react-router-dom";
import ReactGA from 'react-ga4';
/** Contexts */
import { UserContext } from './context/UserContext';
/** Custom components */
import ErrorBoundary from './utils/ErrorBoundary';
import PrivateRoute from './components/PrivateRoute';
import Home from './containers/Home';
import About from './containers/About';
import Login from './containers/Login';
import Register from './containers/Register';
import Profile from './containers/Profile';
import GoalsDashboard from './containers/Dashboard';
import SetGoal from './containers/Goals/SetGoal';
import Checkin from './containers/Checkins';
import ForgotPassword from './containers/ForgotPassword';
import ResetPassword from './containers/ResetPassword';
import AdminDash from './containers/AdminDash';
import UserReport from './containers/UserReport';
import ContactUs from "./containers/ContactUs";
/** Styles */
import "./App.scss";

export default function App() {
  // eslint-disable-next-line
  const [ userContext, setUserContext ] = useContext(UserContext);
  const history = useHistory();

  const verifyUser = useCallback(() => {
    try {
      fetch("/api/user/refreshToken", {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
      })
      .then(async response => {
        if ( response.ok ) {
          const data = await response.json();
          setUserContext(oldValues => {
            return { ...oldValues, token: data.token, role: data.role }
          });
        } else {
          setUserContext(oldValues => {
            return { ...oldValues, token: null, role: null }
          });
        }
        // call refreshToken to renew every five minutes
        setTimeout(verifyUser, 5 * 60 * 1000);
      });
    } catch ( error ) {
      console.log(error);
    }
  }, [ setUserContext ]);

  useEffect(() => {
    verifyUser();
  }, [ verifyUser] );

  const syncLogout = useCallback(event => {
    if (event.key === "logout") {
      history.push("/");
    }
  }, [history]);

  useEffect(() => {
    window.addEventListener("storage", syncLogout);
    return () => {
      window.removeEventListener("storage", syncLogout);
    }
  }, [syncLogout]);

  const setGA = () => {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search});
  };

  useEffect(() => {
    setGA();
  });

  return (
    <ErrorBoundary>
      <Router>
        <Switch>
          <Route exact path="/">
            <Home /> 
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/register">
            <Register />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/forgot-password">
            <ForgotPassword />
          </Route>
          <Route path="/contact">
            <ContactUs />
          </Route>
          <Route path="/reset-password/:uid/:token" component={ ResetPassword } />
          <Route path="/reset-password/" component={ ResetPassword } />
          <PrivateRoute path="/dashboard" component={GoalsDashboard} />
          <PrivateRoute path="/profile" component={Profile} />
          <PrivateRoute path="/goal/set" component={SetGoal} />
          <PrivateRoute path="/administrate" component={AdminDash} />
          <PrivateRoute path="/report" component={UserReport} />
          <PrivateRoute path="/checkin/:goalId" component={ Checkin } />
        </Switch>
      </Router>
    </ErrorBoundary>
  );
}