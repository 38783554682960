import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { faPhoneSquare, faLink, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { UserProvider } from './context/UserContext';

import 'bootstrap/dist/css/bootstrap.min.css';

/**
 * Initialize global fontawesome
 */
 library.add( fab, faPhoneSquare, faLink, faEnvelope );

ReactDOM.render(
    <React.StrictMode>
      <UserProvider>
        <App />
      </UserProvider>
    </React.StrictMode>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
