/* eslint-disable no-unused-vars */
import { useState, useReducer, useCallback, useEffect, useContext } from 'react';
import {
  Row,
  Col,
  Modal,
  Button,
  Card,
  Table
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTachometerAlt,
  faPlus,
  faDownload,
  faRoute,
  faCheckCircle,
  faPlayCircle,
  faPauseCircle
} from '@fortawesome/free-solid-svg-icons';
/** Custom Imports */
import Loader from '../../components/Loader';
import ListGoal from '../../components/ListGoal';
import Layout from '../../components/Layout';
/** Context */
import { UserContext } from '../../context/UserContext';
/** Styles */
import styles from './styles.module.scss';

const GoalsDashboard = () => {
  const [ isRetrieving, setIsRetrieving ] = useState(true);
  const [ error, setError ] = useState("");
  const [ show, toggle ] = useReducer( state => !state, true );
  const [ userGoals, setUserGoals ] = useState([]);
  const [ userContext, setUserContext ] = useContext(UserContext);
  const genericErrorMessage = "Something went wrong. Please try again.";

  const fetchUserGoals = useCallback(() => {
    if ( userContext.token ) {
      fetch("/api/goal", {
        method: 'GET',
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${ userContext.token }`,
        },
      })
      .then(async response => {
        if ( response.ok ) {
          const data = await response.json();
          setUserGoals(data);
        } else {
          toggle();
          setError(response?.message)
          if ( response.status === 404 ) {
            console.log('huh');
          }
        }
      })
      .catch(error => {
        toggle();
        setError(error.message || genericErrorMessage);
      })
      .finally(() => {
        setIsRetrieving(false);
      });
    }
  }, [ userContext.token, setUserGoals, toggle, setError, setIsRetrieving]);

  useEffect(() => {
    fetchUserGoals();
  }, [fetchUserGoals]);

  const headerDisplayCount = ( count ) => {
    if ( count !== 1 ) {
      return `${ count } goals`
    } else {
      return `${ count } goal`
    }
  };

  return (
    <Layout>
      <div className={styles.dashboard + ` dashboard`}>
        {
          error &&
          <Modal
            show={show}
            onHide={ toggle }
            aria-labelledby="error-modal"
            centered
          >
            <Modal.Header>
              <Modal.Title>
                Error
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              { error }
            </Modal.Body>
          </Modal>
        }
        <Row className="content page-heading mb-4">
          <Col sm={12} md={7}>
            <h1 className="h3 text-gray-800">
              <FontAwesomeIcon icon={ faTachometerAlt } className="text-gray-500 d-none d-sm-inline" aria-hidden="true" />
              {' '}
              Dashboard
            </h1>
          </Col>
          <Col sm={12} md={5} className="d-md-flex justify-content-md-end actions">
            <Button
              variant="success"
              href="/goal/set"
              title="Set New Goal"
              className="shadow-sm px-4 mr-4 w-auto"
            >
              <FontAwesomeIcon icon={ faPlus } className="text-white-50" aria-hidden="true" />
              {' '}
              Set New Goal
            </Button>
            {' '}
            <Button
              variant="secondary"
              href="/report"
              title="Report"
              className="shadow-sm px-4 w-auto"
            >
              <FontAwesomeIcon icon={ faDownload } className="text-white-50" size="sm" aria-hidden="true" />
              {' '}
              Create Report
            </Button>
          </Col>
        </Row>
        { isRetrieving ? (
          <Loader />
        ) : (
          <>
            <Row className="content dashboard-overview">
              <Col md={6} className="d-none d-md-block mb-4">
                <Card className="bg-info text-white shadow h-100 py-2">
                  <Card.Body>
                    <Row className="no-gutters align-items-center">
                      <Col className="mr-4">
                        <div className="text-xs font-weight-bold text-uppercase">In Progress</div>
                        <div className="h3 mb-0 font-weight-bold text-uppercase">{ headerDisplayCount( userGoals.progress.length ) }</div>
                      </Col>
                      <Col className="col-auto d-none d-md-block">
                        <FontAwesomeIcon icon={ faRoute } size="2x"  aria-hidden="true" />
                      </Col>
                    </Row>
                  </Card.Body>
                  
                </Card>
              </Col>
              <Col md={6} className="d-none d-md-block mb-4">
                <Card className="bg-success text-white shadow h-100 py-2">
                  <Card.Body>
                    <Row className="no-gutters align-items-center">
                      <Col className="mr-4">
                        <div className="text-xs font-weight-bold text-uppercase">Completed</div>
                        <div className="h3 mb-0 font-weight-bold text-uppercase">{ headerDisplayCount( userGoals.complete.length ) }</div>
                      </Col>
                      <Col className="col-auto d-none d-md-block">
                        <FontAwesomeIcon icon={ faCheckCircle} size="2x" aria-hidden="true" />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="content dashboard-active">
              <Col>
                <Card id="active" className="border-bottom-primary shadow">
                  <Card.Header className="bg-primary py-3">
                    <h6 className="m-0 font-weight-bold text-uppercase text-white">
                      <FontAwesomeIcon icon={ faPlayCircle } className="text-white-50" aria-hidden="true" />
                      {' '}
                      Active Goals
                    </h6>
                  </Card.Header>
                  <Card.Body>
                    <Table responsive striped hover size="sm" className="dashboard">
                      <thead>
                        <tr className="d-none d-md-table-row">
                          <th className="dashboard-status">
                            <span className="sr-only">Rating/Status</span>
                          </th>
                          <th className="dashboard-goal">
                            Goal
                          </th>
                          {/*<th className="dashboard-progress d-none d-md-table-cell">
                            Progress
                          </th>*/}
                          <th className="dashboard-target d-none d-md-table-cell">
                            Target
                          </th>
                          <th className="dashboard-action">
                            <span className="sr-only">Action</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        { 
                          userGoals.progress.map(function (goal) {
                            return <ListGoal key={goal._id} goal={goal} />
                          })
                        }
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="content dashboard-hold mt-4">
              <Col>
                <Card id="hold" className="border-bottom-secondary shadow">
                  <Card.Header className="bg-secondary py-3">
                    <h6 className="m-0 font-weight-bold text-uppercase text-white">
                      <FontAwesomeIcon icon={ faPauseCircle } className="text-white-50" aria-hidden="true" />
                      {' '}
                      Goals on Hold
                    </h6>
                  </Card.Header>
                  <Card.Body>
                    <Table responsive striped hover size="sm" className="dashboard">
                      <thead>
                        <tr className="d-none d-md-table-row">
                          <th className="dashboard-status">
                            <span className="sr-only">Status</span>
                          </th>
                          <th className="dashboard-goal">
                            Goal
                          </th>
                          {/*<th className="dashboard-progress d-none d-md-table-cell">
                            Progress
                          </th>*/}
                          <th className="dashboard-target d-none d-md-table-cell">
                            Target
                          </th>
                          <th className="dashboard-action">
                            <span className="sr-only">Action</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        { 
                          userGoals.hold.map(function( goal ) {
                            return <ListGoal key={goal._id} goal={goal} />
                          })
                        }
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="content dashboard-complete my-4">
              <Col>
                <Card id="active" className="border-bottom-success shadow">
                  <Card.Header className="bg-success py-3">
                    <h6 className="m-0 font-weight-bold text-uppercase text-white">
                      <FontAwesomeIcon icon={ faCheckCircle } className="text-white-50" aria-hidden="true" />
                      {' '}
                      Completed Goals
                    </h6>
                  </Card.Header>
                  <Card.Body>
                    <Table responsive striped hover size="sm" className="dashboard">
                      <thead>
                        <tr className="d-none d-md-table-row">
                          <th className="dashboard-status">
                            <span className="sr-only">Status</span>
                          </th>
                          <th className="dashboard-goal">
                            Goal
                          </th>
                          {/*<th className="dashboard-progress d-none d-md-table-cell">
                            Progress
                          </th>*/}
                          <th className="dashboard-target d-none d-md-table-cell">
                            Target
                          </th>
                          <th className="dashboard-action">
                            <span className="sr-only">Action</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        { 
                          userGoals.complete.map(function (goal) {
                            return <ListGoal key={goal._id} goal={goal} />
                          })
                        }
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </div>
    </Layout>
  );
};

export default GoalsDashboard;