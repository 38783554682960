import React, { useContext } from 'react';
import {
  Row,
  Col,
  Card,
  Image
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faProjectDiagram, faShieldAlt, faCogs, faRoute } from '@fortawesome/free-solid-svg-icons';
/** Custom Imports */
import Layout from '../../components/Layout';
/** Contexts */
import { UserContext } from '../../context/UserContext';
/** Assets */
import cmfrLogo from "../../assets/cmfr_tag.png";

/** Styles */
import styles from './styles.module.scss';

const Home = () => {
  const [ userContext ] = useContext(UserContext);
  return (
    <Layout>
      <div className="home">
        <Row className="content hero">
          <Col sm={12}>
            <FontAwesomeIcon 
              icon={ faProjectDiagram }
              size="5x"
              className="text-primary float-right rotate-n-15"
              aria-label="ADAPTability Icon"
            />
            <h1 className="mb-4">
              <span className="display-4 text-gray-600 text-uppercase">
                Welcome to
              </span><br />
              <span className="display-2 font-weight-bold text-primary">
                ADAPTability
              </span>
            </h1>
            <p className="lead">
            This tool is available to use in conjunction with the Air Force Alcohol and Drug Abuse Prevention and Treatment (ADAPT) program to enhance your ADAPT experience. You can use this tool to record treatment goals; update your progress; and keep track of your ADAPT treatment.
            </p>
            <p className="lead">
            ADAPTability is confidential. You may choose to download and share this information with ADAPT staff; however, all information is confidential and will only be shared if <strong>you</strong> choose to share it.
            </p>
            { !userContext.token &&
              <p className="lead font-weight-bold">
                To get started{' '}
                <a href="/register">register</a> or <a href="/login">log in</a>.
              </p>
            }
          </Col>
        </Row>
        <Row className="content features my-4">
          <Col sm={12} md={4} className="mb-4 mb-md-0">
            <Card className="bg-info text-white shadow h-100 py-2">
              <Card.Body>
                <Row className="no-gutters align-items-center">
                  <Col className="col-auto mr-4" aria-hidden="true">
                    <FontAwesomeIcon icon={ faShieldAlt } size="3x" />
                  </Col>
                  <Col>
                    <div className="h4 mb-0 font-weight-bold">Free and Confidential</div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={12} md={4} className="mb-4 mb-md-0">
            <Card className="bg-primary text-white shadow h-100 py-2">
              <Card.Body>
                <Row className="no-gutters align-items-center">
                  <Col className="col-auto mr-4" aria-hidden="true">
                    <FontAwesomeIcon icon={ faCogs } size="3x" />
                  </Col>
                  <Col>
                    <div className="h4 mb-0 font-weight-bold">Enhance Your Experience</div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={12} md={4} className="mb-4 mb-md-0">
            <Card className="bg-success text-white shadow h-100 py-2">
              <Card.Body>
                <Row className="no-gutters align-items-center">
                  <Col className="col-auto mr-4" aria-hidden="true">
                    <FontAwesomeIcon icon={ faRoute } size="3x" />
                  </Col>
                  <Col>
                    <div className="h4 mb-0 font-weight-bold">Track Your Progress</div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className={styles.cmfrFooter + " content colophon justify-content-between align-self-center mb-4"}>
          <Col>
            <Card className="p-4">
              <Row className="justify-content-between">
                <Col sm={12} md={4}>
                  <a
                    href="https://militaryfamilies.psu.edu"
                    className="logo"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <Image 
                      src={cmfrLogo}
                      alt="Clearinghouse for Military Family Readiness Mark"
                      className={styles.cmfrLogoFtr}
                    />
                    <p className="tag">A Penn State Applied Research Center</p>
                  </a>
                </Col>
                <Col sm={12} md={4}>
                  <p className="address">
                    The Pennsylvania State University<br />
                    402 Marion Place<br />
                    135 E. Nittany Ave<br />
                    State College, PA 16801
                  </p>
                </Col>
                <Col sm={12} md={4}>
                  <p className="phone">
                    <a
                      href="tel:1-877-382-9185"
                      title="Call the Clearinghouse for Military Family Readiness"
                    >
                      <FontAwesomeIcon icon="phone-square" aria-hidden="true" />
                      {' '}1-877-382-9185
                    </a>
                  </p>
                  <p className="web">
                    <a
                      href="https://militaryfamilies.psu.edu"
                      title="Visit the Clearinghouse for Military Family Readiness Website"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <FontAwesomeIcon icon="link" aria-hidden="true" />
                      {' '}militaryfamilies.psu.edu
                    </a>
                  </p>
                  <p className="support">
                    If you are experiencing issues with this website, please contact our{' '}
                    <a
                      href="mailto:webadmincmfr@psu.edu"
                      title="Email the Clearinghouse Web Administrator"
                    >
                      web administrator
                    </a>
                    .
                  </p>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </Layout>
  );
}

export default Home;