import { useContext, useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import Loader from '../Loader';
import { UserContext } from '../../context/UserContext';

export default function PrivateRoute(props) {
  const { setUserContext } = useContext(UserContext);
  const [ isLoading, setIsLoading ] = useState(true);
  const [ auth, setAuth ] = useState('');

  useEffect(() => {
    const verifyUser = async () => {
      const res = await fetch("/api/user/refreshToken", {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" }
      });
      if ( res.ok || res.status === 200 ) {
        setAuth(true);
      } else {
        setAuth(false);
      }

      setIsLoading(false);
    };

    verifyUser();
  }, [setUserContext]);

  const { component: Component, ...rest } = props;

  if ( isLoading ) {
    return <Loader />
  }

  if ( auth ) {
    return ( <Route {...rest} render={(props) => ( <Component {...props}/> )} />);
  } else {
    return <Redirect to='/login' />;
  }
}