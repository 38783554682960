import React, { useContext } from "react";
import { Nav, Navbar, Dropdown, Image, Button } from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faSignOutAlt, faPencilAlt, faLock, faCog, faBars } from "@fortawesome/free-solid-svg-icons";
/** Contexts */
import { UserContext } from '../../context/UserContext';
/** Media Elements */
import psuLogo from "../../assets/psu_logo_hor_blue.png";
import cmfrLogo from "../../assets/cmfr_tag_blue.png";
/** Styles */
import styles from './styles.module.scss';

/**
 * Renders presentational navigation element based upon global
 * authentication status
 */
const NavBar = ({
  handleCollapse,
  handleToggleSidebar
}) => {
  // eslint-disable-next-line
  const [ userContext, setUserContext ] = useContext(UserContext);
  const history = useHistory();

  const logoutHandler = () => {
    fetch("/api/user/logout", {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ userContext.token }`,
      },
    })
    .then( async response => {
      setUserContext(oldValues => {
        return { ...oldValues, details: undefined, token: null };
      });
      window.localStorage.setItem("logout", Date.now());
      history.push("/");
    });
  }

  return (
    <Navbar className={ styles.adapt_nav + " navar navbar-expand navbar-light bg-white topbar mb4 static-top shadow" }>
      <Button
        onClick={() => handleToggleSidebar(true)}
        variant="primary"
        className={styles.sidebarToggle + " mr-3"}
      >
        <FontAwesomeIcon icon={ faBars } size="1x" aria-hidden="true" className={styles.toggleIcon} />
      </Button>
      <span className={styles.collapseToggle}>
        <Button
          onClick={ handleCollapse }
          variant="primary"
          className="mr-3"
        >
          <FontAwesomeIcon icon={ faBars } size="1x" aria-hidden="true" className={styles.toggleIcon} />
        </Button>
      </span>
      <Nav.Link href="https://www.psu.edu/" target="_blank" className="d-none d-md-inline-block">
        <Image
          src={ psuLogo }
          alt="The Pennsylvania State University mark"
          className={styles.psuHeaderLogo}
        />
      </Nav.Link>
      <Nav.Link href="https://militaryfamilies.psu.edu/" target="_blank" className="d-none d-md-inline-block">
        <Image
          src={ cmfrLogo }
          alt="Clearinghouse for Military Family Readiness mark" 
          className={styles.cmfrHeaderLogo}
        />
      </Nav.Link>
      <Navbar.Toggle aria-controls="cmfr-upload-navbar" />
      <Navbar.Collapse id="cmfr-navbar-nav">
        <Nav className="ml-auto w-100 d-flex justify-content-end">
        { !userContext.token ? (
            <>
              <Nav.Link href="/register" className="btn btn-secondary text-white mr-3 flex-fill flex-md-grow-0">
                <FontAwesomeIcon icon={ faPencilAlt } size="sm" fixedWidth className="text-white-50" aria-hidden="true" />
                {' '}Register</Nav.Link>
              <Nav.Link href="/login" className="btn btn-info text-white flex-fill flex-md-grow-0">
                <FontAwesomeIcon icon={ faLock } size="sm" fixedWidth className="text-white-50" aria-hidden="true" />
                {' '}Log In
              </Nav.Link>
            </>
          ) : (
            <Dropdown>
              <Dropdown.Toggle variant="none" id="userDropdown" className="btn btn-info text-white">
                <FontAwesomeIcon icon={ faUser } size="sm" fixedWidth className="text-white-50" aria-hidden="true" />
                {' '}Your Account
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="/profile">
                  <FontAwesomeIcon icon={ faUser } size="sm" fixedWidth className="mr-2 text-gray-400" aria-hidden="true" />
                  {' '}Your Profile
                </Dropdown.Item>
                <Dropdown.Divider />
                { userContext.role === 'admin' &&
                  <Dropdown.Item href="/administrate">
                    <FontAwesomeIcon icon={ faCog } size="sm" fixedWidth className="mr-2 text-gray-400" aria-hidden="true" />
                    {' '}Admin
                  </Dropdown.Item>
                }
                <Dropdown.Item onClick={logoutHandler}>
                  <FontAwesomeIcon icon={ faSignOutAlt } size="sm" fixedWidth className="mr-2 text-gray-400" aria-hidden="true" />
                  {' '}Logout
                </Dropdown.Item>
              </Dropdown.Menu>
              
            </Dropdown>
          )
        }
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};
export default NavBar;