import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSmile,
  faMeh,
  faFrown
} from '@fortawesome/free-solid-svg-icons';
/** Custom Imports */
/** Styles  */
// import styles from './styles.module.scss';
const ListCheckin = ( props ) => {
  const {
    rating,
    reflection,
    // eslint-disable-next-line no-unused-vars
    _id,
    createdAt
  } = props.checkin;

  const setReadableDate = ( date ) => {
    if ( date ) {
      let value = new Date(date);
      return value.toLocaleString('en-US', { timeZone: 'America/New_York'}).split(', ')[0];
    } else {
      return '';
    }
  };

  const setRating = ( rating ) => {
    if ( rating ) {
      if ( rating === 1 ) {
        return <FontAwesomeIcon icon={ faFrown } size="2x" className="text-danger" title="Needs Improvement" />;
      }
      if ( rating === 2 ) {
        return <FontAwesomeIcon icon={ faMeh } size="2x" className="text-warning" title="Okay" />;
      }
      if ( rating === 3 ) {
        return <FontAwesomeIcon icon={ faSmile } size="2x" className="text-success" title="Good" />;
      }
    } else {
      return <strong className="text-muted text-center">Not Rated</strong>
    }
  };

  return (
    <tr>
      <td className="history-date">{ setReadableDate(createdAt) }</td>
      <td className="history-rating">
        { setRating( rating ) }
      </td>
      <td className="history-reflection">
        { reflection }
      </td>
    </tr>
  );
};

export default ListCheckin;