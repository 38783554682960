import React, { Component } from "react";
import { 
  Container,
  Image,
  Nav
} from "react-bootstrap";
/** Media Elements */
import psuLogo from "../../assets/psu_logo_hor_blue.png";
/** Styles */
import styles from "./styles.module.scss";

/**
 * Renders footer presentational element
 */
export default class Footer extends Component {
  render() {
    let currentYear = new Date().getFullYear();
    return (
      <footer className={styles.cmfrFooter}>
        <Container fluid className={styles.cmfrSubFtr + " shadow"}>
          <Nav className="justify-content-center align-items-center">
            <Nav.Item className="logo">
              <Nav.Link href="https://www.psu.edu" target="_blank">
                <Image
                  src={psuLogo}
                  alt="The Pennsylvania State University mark"
                  className={styles.psuSubFtrLogo}
                />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="https://www.psu.edu/web-privacy-statement" target="_blank" rel="noreferrer noopener">
                Privacy
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>&bull;</Nav.Item>
            <Nav.Item>
              <Nav.Link href="https://policy.psu.edu/policies/AD85/" target="_blank" rel="noreferrer noopener">
                Non-discrimination
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="d-none d-md-inline">&bull;</Nav.Item>
            <div className="d-block d-md-none w-100"></div>
            <Nav.Item>
              <Nav.Link href="https://policy.psu.edu/policies/hr11/" target="_blank" rel="noreferrer noopener">
                Equal Opportunity
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>&bull;</Nav.Item>
            <Nav.Item>
              <Nav.Link href="https://www.psu.edu/accessibilitystatement" target="_blank" rel="noreferrer noopener">
                Accessibility
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="d-none d-md-inline">&bull;</Nav.Item>
            <Nav.Item>
              <Nav.Link href="https://www.psu.edu/copyright-information" target="_blank" rel="noreferrer noopener">
                Copyright &copy; {currentYear} The Pennsylvania State University
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Container>
      </footer>
    );
  }
};
