import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
export default class ErrorBoundary extends Component {
  state = {
    error: '',
    errorInfo: '',
    hasError: false,
  };

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line no-console
    console.log({ error, errorInfo });
    this.setState({ errorInfo });
  }

  render() {
    const { hasError, errorInfo } = this.state;
    if (hasError) {
      return (
        <Card>
          <Card.Header>
            Error
          </Card.Header>
          <Card.Body>
            <Card.Text>
              There was an error loading this page.{' '}
              <details className="error-details">
                <summary>Click for error details</summary>
                { errorInfo && errorInfo.componentStack.toString() }
              </details>
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <span style={{ cursor: 'pointer', color: '#0077FF' }}
            onClick={() => {
              window.location.reload()
            }}
            >
              Reload this page
            </span>{' '}
          </Card.Footer>
        </Card>
      );
    }
    return this.props.children;
  }
};

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([ PropTypes.object, PropTypes.array ]).isRequired,
};
