import React, {useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Col,
  Form,
  Card,
  Modal,
  Row
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faMapMarkerAlt,
  faCalendar,
  faClock,
  faMountain,
  faClipboardList,
  faMapMarker
 } from '@fortawesome/free-solid-svg-icons';
 /** Contexts */
 import { UserContext } from '../../../context/UserContext';
/** Custom Imports */
import Layout from '../../../components/Layout';
/** Styles */
// import styles from './styles.module.scss';

const SetGoal = () => {
  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const [ error, setError ] = useState("");
  const [ show, setShow] = useState(false);
  const [ title, setTitle] = useState("");
  const [ hasTargetDate, setHasTargetDate ] = useState(false);
  const [ targetStart, setTargetStart ] = useState("");
  const [ targetEnd, setTargetEnd ] = useState("");
  const [ obstacles, setObstacles ] = useState("");
  const [ plan, setPlan ] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [ userContext, setUserContext ] = useContext(UserContext);
  const history = useHistory();

  const cancelFormSubmit = e => {
    e.preventDefault();
    history.push("/dashboard");
  };

  const formSubmitHandler = e => {
    e.preventDefault();
    setIsSubmitting(true);
    setError("");
    const bodyValues = {
      title: title,
      hasTargetDate: hasTargetDate,
      targetStartDate: targetStart,
      targetEndDate: targetEnd,
      obstacles: obstacles,
      plan: plan
    };
    const genericErrorMessage = "Something went wrong. Please try again.";
    fetch("/api/goal", {
      method: "POST",
      credentials: "include",
      headers: { 
        "Content-Type": "application/json",
        Authorization: `Bearer ${ userContext.token }`,
      },
      body: JSON.stringify( bodyValues ),
    })
    .then( async response => {
      console.log(response)
      setIsSubmitting(false);
      if ( !response.ok || response.status !== 200 ) {
        setShow(true);
        if ( response.status === 500 || response.status === 501 ) {
          const data = await response.json();
          if ( data.message ) setError( data.message || genericErrorMessage );
        } else {
          setError( genericErrorMessage );
        }
      } else {
        history.push("/dashboard");
      }
    })
    .catch( error => {
      setShow(true);
      setIsSubmitting(false);
      setError(`Error: ${ error }`);
    });
  };

  return (
    <Layout>
      <div className="goals">
        {
          error &&
          <Modal
            show={show}
            onHide={() => setShow(false)}
            aria-labelledby="error-modal"
            centered
          >
            <Modal.Header>
              <Modal.Title>
                Error
              </Modal.Title>
              <Modal.Body>
                { error }
              </Modal.Body>
            </Modal.Header>
          </Modal>
        }
        <Row className="content hero">
          <Col>
            <FontAwesomeIcon icon={ faMapMarkerAlt } size="5x" className="text-primary float-right" aria-hidden="true" />
            {' '}
            <h1 className="mb-4">
              <span className="display-4 text-gray-600 text-uppercase">Set a Goal</span><br />
            </h1>

            <p className="lead">
              <strong>Setting appropriate goals is an integral component of effective ADAPT treatment.</strong>
              {' '}It is recommended that all goal setting be done with your ADAPT treatment team. Take a moment to think about one of your goals,
              {' '}then complete the form below to begin tracking it.
            </p>
            <p className="mb-0">
              As you think about your goal, think about a way you could handle these situations better by
            </p>
            <ul>
              <li>Managing how you <em>feel</em></li>
              <li>Managing how you <em>think</em></li>
              <li>Managing what you <em>do</em></li>
            </ul>
          </Col>
        </Row>
        <Row className="content mt-4">
          <Col>
            <Card className="border-top-primary border-bottom-primary pt-4 mb-4">
              <Card.Body>
                <Form onSubmit={formSubmitHandler}>
                  <Row className="form-row goal-title">
                    <Col className="col-auto d-none d-md-block icon">
                      <FontAwesomeIcon icon={ faMapMarker } size="2x" aria-hidden="true" />
                    </Col>
                    <Col>
                      <Form.Group className="form-group">
                        <Form.Label>Target</Form.Label>
                        <Form.Text className="mb-3">
                          What change would I like to make?
                        </Form.Text>
                        <Form.Control
                          id="title"
                          placeholder="Example: Stop smoking"
                          type="text"
                          value={title}
                          onChange={e => setTitle(e.target.value)}
                          required
                          className="form-control"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="form-row goal-date">
                    <Col className="col-auto d-none d-md-block icon">
                      <FontAwesomeIcon icon={ faCalendar } size="2x" aria-hidden="true" />
                    </Col>
                    <Col>
                      <Form.Group className="form-group">
                        <Form.Label>
                          Time-Limited
                        </Form.Label>
                        <Form.Text className="mb-3">Do I want to reach my goal by a specific date, or is my goal ongoing?</Form.Text>
                        {[
                          { value: true, readable: 'Yes, I have a target date.'},
                          { value: false, readable: 'No, this goal is ongoing.'}
                        ].map((key) => (
                          <Form.Check
                            inline
                            label={key.readable}
                            name="goal-date"
                            type="radio"
                            value={key.value}
                            key={key.value}
                            className="form-check-inline d-inline-flex align-items-center"
                            onChange={e => setHasTargetDate(e.target.value)}
                          />
                        ))}
                      </Form.Group>
                    </Col>
                  </Row>
                  { hasTargetDate === "true" &&
                    <Row className="form-row goal-date-start-end">
                      <Col className="col-auto d-none d-md-block icon">
                        <FontAwesomeIcon icon={ faClock } size="2x" aria-hidden="true" />
                      </Col>
                      <Col>
                        <Form.Group class="form-group mb-4 pb-4">
                          <Form.Label>
                            What is my starting date?
                          </Form.Label>
                          <Form.Text className="mb-3">When would I like to start this goal?</Form.Text>
                          <Form.Control
                            id="goal-start-date"
                            type="date"
                            value={targetStart}
                            onChange={e => setTargetStart(e.target.value)}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="form-group">
                          <Form.Label>
                            What is my end date?
                          </Form.Label>
                          <Form.Text className="mb-3">When would I like to finish this goal?</Form.Text>
                          <Form.Control
                            id="goal-end-date"
                            type="date"
                            value={targetEnd}
                            onChange={e => setTargetEnd(e.target.value)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  }
                  <Row className="form-row goal-obstacles">
                    <Col className="col-auto d-none d-md-block icon">
                      <FontAwesomeIcon icon={ faMountain } size="2x" aria-hidden="true" />
                    </Col>
                    <Col>
                      <Form.Group className="form-group">
                        <Form.Label>
                          Obstacles
                        </Form.Label>
                        <Form.Text className="mb-3">
                          What are the obstacles to making this change?
                        </Form.Text>
                        <Form.Control
                          id="goalObstalces"
                          as="textarea"
                          placeholder="Example: My partner smokes. I have a very stressful job and turn to smoking to deal with my stress."
                          rows="6"
                          value={obstacles}
                          onChange={e => setObstacles(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="form-row goal-plan">
                    <Col className="col-auto d-none d-md-block icon">
                      <FontAwesomeIcon icon={ faClipboardList } size="2x" aria-hidden="true" />
                    </Col>
                    <Col>
                      <Form.Group className="form-group">
                        <Form.Label>
                          Plan
                        </Form.Label>
                        <Form.Text className="mb-3">
                          What exactly do <strong>I</strong> need to do to make this change?
                          <br />
                          <br />Your plan should include specific steps that you will take to achieve
                          {' '}this goal. Your steps to achieve your goal should be
                          {' '}<strong>SMART</strong>: <strong>S</strong>pecific, <strong>M</strong>easurable, <strong>A</strong>ttainable, <strong>R</strong>elevant, and <strong>T</strong>ime-Limited.
                        </Form.Text>
                        <Form.Control
                          id="goalPlan"
                          as="textarea"
                          rows="6"
                          placeholder="Example: Throw out my cigarettes tonight after dinner. Buy chewing gum to help with cravings."
                          value={plan}
                          onChange={e => setPlan(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col className="d-flex justify-content-end">
                        <Button
                          variant="primary"
                          className="ml-auto mr-3 btn-lg flex-fill flex-md-grow-0"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          {`${ isSubmitting ? "Setting Goal" : "Set Goal"}`}
                        </Button>
                        {' '}
                        <Button
                          variant="danger"
                          onClick={cancelFormSubmit}
                          className="btn-lg flex-fill flex-md-grow-0"
                        >
                          Cancel
                        </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default SetGoal;