import React, { useContext, useState } from 'react';
import {
  Button,
  Col,
  Form,
  Card,
  Modal,
  Row
} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
/** Custom Imports */
import Layout from '../../components/Layout';
/** Context */
import { UserContext } from '../../context/UserContext';
/** Styles */
// import styles from './styles.module.scss';

const Login = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // eslint-disable-next-line
  const [userContext, setUserContext] = useContext(UserContext);
  const history = useHistory();

  const formSubmitHandler = e => {
    e.preventDefault();
    setIsSubmitting(true);
    setError("");

    const genericErrorMessage = "Something went wrong. Please try again later.";

    fetch("/api/user/login", {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ username: email, password }),
    })
    .then(async response => {
      setIsSubmitting(false);
      if ( !response.ok ) {
        setShow(true);
        if ( response.status === 400 ) {
          setError("Please ensure all fields have been filled in.");
        } else if ( response.status === 401 ) {
          setError("Invalid email or password.");
        } else {
          setError(genericErrorMessage);
        }
      } else {
        setShow(false);
        const data = await response.json();
        setUserContext(oldValues => {
          return { ...oldValues, token: data.token }
        });
        console.log('data in Login: ', data);
        history.push("/dashboard");
      }
    })
    .catch(error => {
      setIsSubmitting(false)
      setError(genericErrorMessage);
    });
  }

  return (
    <Layout>
      <div className="login">
        { 
          error &&
          <Modal
            show={show}
            onHide={() => setShow(false)}
            aria-labelledby="error-modal"
            centered
          >
            <Modal.Header>
              <Modal.Title>
                Error
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {error}
            </Modal.Body>
          </Modal>
        }
        <Row className="d-flex justify-content-center">
          <Col sm={12} md={6}>
            <Card className="border-bottom-primary mx-4 mx-md-0">
              <Card.Header className="card-header bg-primary py-3">
                <h1 className="h6 m-0 font-weight-bold text-uppercase text-white text-center">
                  Welcome Back!
                </h1>
              </Card.Header>
              <Card.Body className="mb-4">
                <Form onSubmit={formSubmitHandler} >
                  <Row>
                    <Col>
                      <Form.Group className="form-row mb-4">
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control 
                          id="email"
                          placeholder="you@email.com"
                          type="email"
                          value={email}
                          onChange={e => setEmail(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="form-row mb-4">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          id="password"
                          placeholder="Password"
                          type="password"
                          value={password}
                          onChange={e => setPassword(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="d-grid gap-2">
                      <Button
                        variant="primary"
                        size="lg"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {`${isSubmitting ? "Logging In" : "Log In" }`}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
              <Card.Footer>
                  <Row className="py-2">
                    <Col className="text-center">
                      <a
                        href="/forgot-password"
                        title="Forgot Password"
                      >
                        Forgot Password?
                      </a>
                    </Col>
                    <Col className="text-center">
                      <a
                        href="/register"
                        title="Create an account"
                      >
                        Create an Account!
                      </a>
                    </Col>
                  </Row>
                </Card.Footer>
            </Card>
          </Col>
        </Row>
      </div>
    </Layout>
  );
}

export default Login;