import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faSmile,
  faMeh,
  faFrown,
  faPencilAlt
} from '@fortawesome/free-solid-svg-icons';
/** Styles */
// import styles from './styles.module.scss';

const ListGoal = ( props ) => {
  const {
    _id,
    title,
    cumulativeRating,
    targetEndDate
  } = props.goal;
  const checkinLink = `/checkin/${_id}`;
  let ratingStatus = '';
  const setReadableDate = ( date ) => {
    if ( date ) {
      let value = new Date(date);
      return value.toLocaleString('en-US').split(', ')[0];
    } else {
      return '';
    }
  };
  if ( cumulativeRating ) {
    switch ( cumulativeRating ) {
      case 1:
        // Needs Improvement
        ratingStatus = <FontAwesomeIcon icon={faFrown} size="2x" className="text-danger" title="Needs Improvement" aria-hidden="true" />;
        break;
      case 2:
        // Ok
        ratingStatus = <FontAwesomeIcon icon={faMeh} size="2x" className="text-warning" title="OK" aria-hidden="true" />;
        break;
      case 3:
        // Good
        ratingStatus = <FontAwesomeIcon icon={faSmile} size="2x" className="text-success" title="Good" aria-hidden="true" />;
        break;
      default:
        ratingStatus = <h2>NA</h2>;
        break;
    }
  }

  return (
    <tr>
      <td className="dashboard-status">{ ratingStatus }</td>
      <td className="dashboard-goal">{ title }</td>
      <td className="dashboard-target d-none d-md-table-cell">
        { setReadableDate(targetEndDate) }
      </td>
      <td className="dashboard-action">
        <a
          href={ checkinLink }
          title="Check in"
        >
          <Button 
            variant="success"
            title="View Goal"
          >
            <FontAwesomeIcon icon={ faPencilAlt } />
            <span className="d-none d-md-inline">{' '} Check In</span>
          </Button>
        </a>
      </td>
    </tr>
  );
};

export default ListGoal;