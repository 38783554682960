import React, { useContext } from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faProjectDiagram,
  faTachometerAlt,
  faQuestionCircle,
  faEnvelope,
  faLifeRing,
  faPhone,
  faSms,
} from "@fortawesome/free-solid-svg-icons";
/** Contexts */
import { UserContext } from "../../context/UserContext";
/** Styles */
//import 'react-bootstrap-drawer/lib/style.css';
import styles from "./styles.module.scss";

const SideBar = ({ collapsed, toggled, handleToggleSidebar }) => {
  const [userContext] = useContext(UserContext);

  return (
    <div className={styles.cmfrSidebar}>
      <ProSidebar
        collapsed={collapsed}
        toggled={toggled}
        breakPoint="md"
        onToggle={handleToggleSidebar}
      >
        <SidebarContent>
          <Menu>
            <MenuItem
              icon={
                <FontAwesomeIcon
                  icon={faProjectDiagram}
                  className="rotate-n-15 fa-2x"
                  aria-label="ADAPTability Icon"
                />
              }
              className="sidebar-title"
            >
              ADAPTability
              <Link to="/" />
            </MenuItem>
            {userContext.token && (
              <MenuItem
                title="View Your Dashboard"
                icon={
                  <FontAwesomeIcon icon={faTachometerAlt} aria-hidden="true" />
                }
              >
                <a href="/dashboard">Dashboard</a>
              </MenuItem>
            )}
            <MenuItem
              title="About"
              icon={
                <FontAwesomeIcon icon={faQuestionCircle} aria-hidden="true" />
              }
            >
              <a href="/about">About</a>
            </MenuItem>
            <MenuItem
              title="Contact Us"
              icon={<FontAwesomeIcon icon={faEnvelope} aria-hidden="true" />}
            >
              <a href="/contact">Contact Us</a>
            </MenuItem>
          </Menu>
        </SidebarContent>
        {!collapsed && (
          <SidebarFooter>
            <div className={styles.crisisFooter + "d-none d-md-inline"}>
              <div className="sidebar-card fade-in mb-4">
                <p className="text-center mb-4">
                  <FontAwesomeIcon
                    icon={faLifeRing}
                    className="text-center text-white-50 mb-2"
                    size="4x"
                  />
                  <br />
                  If you find yourself in crisis,
                  <br />
                  help is available.
                </p>
                <ul className="fa-ul">
                  <li className="mb-4">
                    <FontAwesomeIcon
                      icon={faPhone}
                      className="fa-li text-white-50"
                    />{" "}
                    National Suicide
                    <br />
                    Prevention Lifeline
                    <br />
                    <a
                      href="tel:18002738255"
                      title="Call the National Suicide Prevention Lifeline"
                    >
                      <strong>1-800-273-8255</strong>
                    </a>
                  </li>
                  <li className="mb-4">
                    <FontAwesomeIcon
                      icon={faSms}
                      className="fa-li text-white-50"
                    />{" "}
                    Veteran Crisis Text
                    <br />
                    <strong>838255</strong>
                  </li>
                </ul>
              </div>
            </div>
          </SidebarFooter>
        )}
      </ProSidebar>
    </div>
  );
};

export default SideBar;
