import React, { useRef, useReducer, useState } from "react";
import { Button, Form, Col, Row, Card, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLifeRing,
  faPhone,
  faSms,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
/** Custom Imports */
import Layout from "../../components/Layout";
/** styles */
import styles from "./styles.module.scss";

const ContactUs = () => {
  const [isSubmitting, setIsSubmitting] = useReducer((state) => !state, false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [show, setShow] = useReducer((state) => !state, false);
  const [messageFirstName, setMessageFirstName] = useState("");
  const [messageLastName, setMessageLastName] = useState("");
  const [messageText, setMessageText] = useState("");
  const [messageEmail, setMessageEmail] = useState("");
  const genericErrorMessage = "Something went wrong. Please try again later.";
  const history = useHistory();
  const recaptchaRef = useRef();
  const SITEKEY = process.env.REACT_APP_CAPTCHA_SITE_KEY;

  const cancelFormSubmit = (e) => {
    e.preventDefault();
    history.push("/");
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting();
    const captchaToken = await recaptchaRef.current.executeAsync();
    if (messageEmail === "" || messageText === "" || messageFirstName === "") {
      setModalTitle("Error");
      let errorMessage = [];
      if (messageFirstName === "") {
        errorMessage.push("<li>Please provide a first name.</li>");
      }
      if (messageEmail === "") {
        errorMessage.push("<li>Please provide an email address.</li>");
      }
      if (messageText === "") {
        errorMessage.push("<li>Please provide a message.</li>");
      }
      setModalMessage(`<ul>${errorMessage.concat("")}</ul>`);
      return;
    } else {
      let formData = {
        ...(messageFirstName && { fromFirstName: messageFirstName }),
        ...(messageLastName && { fromLastName: messageLastName }),
        ...(messageEmail && { fromEmail: messageEmail }),
        ...(messageText && { messageBody: messageText }),
        ...(captchaToken && { captchaToken: captchaToken }),
      };
      fetch("/api/utility/verify-contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      })
        .then(async (response) => {
          if (response.status === 200 || response.ok) {
            setModalTitle("Success!");
            setModalMessage(response.message || "Thank you for contacting us.");
            setMessageFirstName("");
            setMessageLastName("");
            setMessageEmail("");
            setMessageText("");
          } else {
            setModalTitle("Error");
            setModalMessage(genericErrorMessage);
          }
        })
        .catch((error) => {
          setModalTitle("Error");
          setModalMessage(error.message || genericErrorMessage);
        })
        .finally(() => {
          setIsSubmitting();
          setShow(true);
        });
    }
  };

  return (
    <Layout>
      <div className={styles.contactUs}>
        {modalMessage && (
          <Modal
            show={show}
            onHide={() => setShow(false)}
            aria-labelledby="response-modal"
            centered
          >
            <Modal.Header>
              <Modal.Title>{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{modalMessage}</Modal.Body>
          </Modal>
        )}
        <Row className="content hero">
          <Col>
            <FontAwesomeIcon
              icon={faEnvelope}
              size="5x"
              pull="right"
              className="text-primary rotate-n-15"
              aria-hidden="true"
            />
            <h1 className="mb-4">
              <span className="display-4 text-gray-600 text-uppercase">
                Contact
              </span>
              <br />
              <span className="display-2 font-weight-bold text-primary">
                ADAPTability
              </span>
            </h1>
            <p className="lead mb-4">
              Do you have a question about ADAPTability, or do you need
              assistance with your account? Use the form below to contact us or
              email us at{" "}
              <a href="mailto:clearinghouse@psu.edu">clearinghouse@psu.edu</a>.{" "}
              Please <strong>do not</strong> include private medical or
              treatment information in your message.
            </p>
          </Col>
        </Row>
        <Row className="content mb-4 pb-4">
          <Col>
            <Card id="contactForm" className="border-bottom-primary">
              <Card.Header className="bg-primary">
                <h2 className="h5 text-uppercase text-white mb-0">
                  Send a Message
                </h2>
              </Card.Header>
              <Card.Body>
                <Form onSubmit={onFormSubmit}>
                  <Row className="form-row mb-4">
                    <Form.Group as={Col} sm={12} md={6} className="form-group">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        id="firstname"
                        placeholder="First Name"
                        value={messageFirstName}
                        onChange={(e) => setMessageFirstName(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group as={Col} sm={12} md={6} className="form-group">
                      <Form.Label>
                        Last Name <span className="text-muted">Optional</span>
                      </Form.Label>
                      <Form.Control
                        id="lastname"
                        placeholder="Last Name"
                        value={messageLastName}
                        onChange={(e) => setMessageLastName(e.target.value)}
                      />
                    </Form.Group>
                  </Row>
                  <Row className="form-row mb-4">
                    <Form.Group as={Col} className="form-group">
                      <Form.Label>Email Address</Form.Label>
                      <Form.Control
                        id="email"
                        type="email"
                        placeholder="you@email.com"
                        value={messageEmail}
                        onChange={(e) => setMessageEmail(e.target.value)}
                      />
                    </Form.Group>
                  </Row>
                  <Row className="form-row mb-4">
                    <Form.Group as={Col}>
                      <Form.Label>Message</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows="6"
                        value={messageText}
                        onChange={(e) => setMessageText(e.target.value)}
                      />
                    </Form.Group>
                  </Row>
                  <Row className="justify-content-center">
                    <Col>
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={SITEKEY}
                        size="invisible"
                      />
                    </Col>
                  </Row>
                  <Row class="form-row">
                    <Col className="d-flex justify-content-end">
                      <Button
                        variant="primary"
                        className="ml-auto mr-3 btn-lg flex-fill flex-md-grow-0"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {`${isSubmitting ? "Sending Message" : "Send"}`}
                      </Button>
                      <Button
                        variant="danger"
                        onClick={(e) => cancelFormSubmit(e)}
                        className="btn-lg flex-fill flex-md-grow-0"
                      >
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="content my-4">
          <Col>
            <Card className="card bg-danger text-white">
              <Card.Body>
                <Row className="d-flex justify-content-around">
                  <Col sm={12} md={1} className="text-center align-self-center">
                    <FontAwesomeIcon
                      icon={faLifeRing}
                      className="text-center mb-4 mb-md-0"
                      size="4x"
                    />
                  </Col>
                  <Col sm={12} md={5} className="align-self-center">
                    <h2 className="h5 font-weight-bold text-center mb-4 mb-md-0">
                      If you find yourself in crisis,
                      <br /> help is available.
                    </h2>
                  </Col>
                  <Col sm={12} md={3}>
                    <ul className="fa-ul">
                      <li>
                        <FontAwesomeIcon
                          icon={faPhone}
                          className="fa-li text-white-50"
                        />{" "}
                        National Suicide
                        <br />
                        Prevention Lifeline
                        <br />
                        <a
                          href="tel:18002738255"
                          title="Call the National Suicide Prevention Lifeline"
                          className="font-weight-bold text-white"
                        >
                          <strong>1-800-273-8255</strong>
                        </a>
                      </li>
                    </ul>
                  </Col>
                  <Col sm={12} md={3}>
                    <ul className="fa-ul">
                      <li>
                        <FontAwesomeIcon
                          icon={faSms}
                          className="fa-li text-white-50"
                        />{" "}
                        Veteran Crisis Text
                        <br />
                        <strong>838255</strong>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default ContactUs;
