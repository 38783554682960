/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Row,
  Col,
  Card,
  Form,
  Modal
} from 'react-bootstrap';
/** Custom Imports */
import Layout from '../../components/Layout';
/** Context */
import { UserContext } from '../../context/UserContext';
/** Assets */
/** Styles */
// import styles from './styles.module.scss';

const ForgotPassword = () => {
  const [ username, setUsername ] = useState('');
  const [ error, setError ] = useState('');
  const [ success, setSuccess ] = useState(false);
  const [ show, setShow ] = useState(false);
  const history = useHistory();
  const [ userContext, setUserContext ] = useContext(UserContext);

  useEffect(() => {
    if ( userContext.details || userContext.token ) {
      return history.push("/profile");
    }
  });

  const formSubmitHandler = e => {
    e.preventDefault();
    if ( username !== '' ) {
      fetch("/api/user/forgot-password", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ username: username }),
      })
      .then(async response => {
        if ( !response.ok || response.status !== 200 ) {
          setShow(true);
          setError("Something went wrong.");
        } else {
          setShow(false);
          setSuccess(true);
        }
      })
      .catch(error => {
        setShow(true);
        setError(`An error occurred: ${ error }`);
      });
    } else {
      setShow(true);
      setError("Email required");
    }
  };

  return(
    <Layout>
      <div className="forgot-password">
        { 
          error &&
          <Modal
            show={show}
            onHide={() => setShow(false)}
            aria-labelledby="error-modal"
            centered
          >
            <Modal.Header>
              <Modal.Title>
                Error
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {error}
            </Modal.Body>
          </Modal>
        }
        <Row className="justify-content-center">
          <Col sm={12} md={6}>
            <Card className="border-bottom-primary mx-4 mx-md-0">
              <Card.Header className="bg-primary py-3">
                <h1 className="h6 m-0 font-weight-bold text-uppercase text-white text-center">
                  Forgot Your Password?
                </h1>
              </Card.Header>
              <Card.Body className="mb-4">
                { success && 
                  <Card.Text className="text-center text-success font-weight-bold">Password Reset Email Sent!</Card.Text>
                }
                <Card.Text className="text-center">
                  Enter your email address below to receive an email with a link to reset your password.
                </Card.Text>
                <Form onSubmit={formSubmitHandler}>
                  <Row className="form-row mb-4">
                    <Col>
                      <Form.Group className="form-group">
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control
                          id="email"
                          placeholder="you@email.com"
                          type="email"
                          onChange={e => setUsername(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button
                        variant="primary"
                        size="lg"
                        type="submit"
                        className="btn-block"
                      >
                        Request Password Reset
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
              <Card.Footer className="pt-4">
                <p className="text-center"><Card.Link href="/register">Need to register? Create your account.</Card.Link></p>
                <p className="text-center" ><Card.Link href="/login">Already have an account? Log in!</Card.Link></p>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default ForgotPassword;