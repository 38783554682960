import React from 'react';
import {
  Card,
  Row,
  Col
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
/** Custom Imports */
import Layout from '../../components/Layout';
/** Styles */
import styles from './styles.module.scss';

const About = () => {
  return (
    <Layout>
      <div className={styles.about + ` about`}>
        <Row className="content hero">
          <Col>
            <FontAwesomeIcon icon={ faQuestionCircle } size="5x" className="text-primary float-right rotate-n-15" aria-hidden="true" />
            <h1 className="mb-4">
              <span className="display-4 text-gray-600 text-uppercase">About</span><br />
              <span className="display-2 font-weight-bold text-primary">ADAPTability</span>
            </h1>
          </Col>
        </Row>
        <Row className="content mb-4">
          <Col>
            <Card>
              <Card.Header>
                <h2>Who is this for?</h2>
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  This product is for patients participating in the US Air Force’s Alcohol and Drug Abuse Prevention and Treatment (ADAPT) program.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card>
              <Card.Header>
                <h2>What is the ADAPTability Tool?</h2>
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  This product is intended to be used to enhance ADAPT treatment. It may be recommended by ADAPT staff,
                  {' '}but it is yours to use as you see fit. Nothing will be shared with anyone unless
                  {' '}<strong>you</strong> share it.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card>
              <Card.Header>
                <h2>Who developed the ADAPTability Tool?</h2>
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  This material is the result of partnership funded by the Department of Defense between the Air Force Medical
                  {' '}Readiness Agency and the USDA’s National Institute of Food and Agriculture through a grant/cooperative
                  {' '}agreement with Penn State.
                </Card.Text>
                <Card.Text>
                  The Clearinghouse for Military Family Readiness at Penn State is the result of a partnership funded by the
                  {' '}Department of Defense between the Office of the Deputy Assistant Secretary of Defense for Military Community
                  {' '}and Family Policy and the USDA’s National Institute of Food and Agriculture through a cooperative agreement with
                  {' '}the Pennsylvania State University. This work leverages funds by the USDA’s National Institute of Food and
                  {' '}Agriculture and Hatch Appropriations.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card>
              <Card.Header>
                <h2>Why should I register?</h2>
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  You should register to use ADAPTability if you would like an easy, private, user-friendly way to keep track
                  {' '}of your treatment goals and treatment progress.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card>
              <Card.Header>
                <h2>When should I use it?</h2>
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  You can use ADAPTability as often as you would like. Some people may prefer to check in daily, weekly, or even
                  {' '}monthly. ADAPTability is customizable so that you can make it work for you.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card>
              <Card.Header>
                <h2>How will this help me?</h2>
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  ADAPTability will help you keep track of your goals and monitor your progress during your time in the ADAPT program.
                  Reminders can be utilized to help keep you on track and provide opportunities for
                  {' '}accountability as you record things that are important to you.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default About;