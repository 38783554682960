/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState, useCallback } from 'react';
import {
  Button,
  ButtonGroup,
  Col,
  Card,
  Modal,
  Row,
  Table
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faSyncAlt, faTrash, faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
/** Custom Imports */
import Layout from '../../components/Layout';
/** Context */
import { UserContext } from '../../context/UserContext';
/** Styles */
import styles from './styles.module.scss';

const AdminDash = () => {
  const [ modalMessage, setModalMessage ] = useState('');
  const [ show, setShow ] = useState(false);
  const [ modalTitle, setModalTitle ] = useState('');
  const [ appUsers, setAppUsers ] = useState([]);
  const [ userContext, setUserContext ] = useContext(UserContext);

  const genericErrorMessage = "Something went wrong. Please try again later.";

  const fetchAppUsers = useCallback(() => {
    fetch("/api/user/", {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ userContext.token }`,
      },
    })
    .then( async response => {
      if ( !response.ok ) {
        setModalTitle('Error');
        setShow(true);
        setModalMessage(`${ response.message || genericErrorMessage }`);
        return;
      } else {
        const data = await response.json();
        setAppUsers(data);
        return;
      }
    })
    .catch(fetchError => {
      setModalTitle(`Error`);
      setShow(true);
      setModalMessage(`${ fetchError || genericErrorMessage }`);
    });
  }, [userContext]);

  useEffect(() => {
    if ( userContext.token ) {
      fetchAppUsers();
    }
  }, [fetchAppUsers, userContext.token]);

  const changeUserRole = (e, id, role) => {
    e.preventDefault();
    fetch(`/api/user/${ id }`, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ userContext.token }`
      },
      body: JSON.stringify({
        role: role
      }),
    })
    .then(response => {
      if ( !response.ok ) {
        setModalTitle("Error");
        setModalMessage(response.message.json() || response.error.json() || genericErrorMessage);
        return;
      } else {
        setModalTitle("Success");
        setModalMessage("User role changed!");
        return;
      }
    })
    .catch(error => {
      setModalTitle("Error");
      setModalMessage(error || genericErrorMessage);
      return;
    })
    .finally(() => {
      setShow(true);
      setTimeout(() => {
        window.location.reload();;
      }, 3000);
    });
  };

  const resetUserPassword = (e, username) => {
    e.preventDefault();
    fetch("/api/user/forgot-password", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ username: username }),
    })
    .then(async response => {
      if ( !response.ok || response.status !== 200 ) {
        setModalTitle("Error");
        setModalMessage(response.message || genericErrorMessage);
        return;
      } else {
        setModalTitle("Success");
        setModalMessage("Password reset successfully sent!");
        return;
      }
    })
    .catch(error => {
      setModalTitle("Error");
      setModalMessage(`An error occurred: ${ error }`);
      return;
    })
    .finally(() => {
      setShow(true);
      return;
    });
  };

  const deleteUser = async (e, id) => {
    e.preventDefault();
    await fetch(`/api/user/${ id }`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ userContext.token }`,
      }
    })
    .then( async response => {
      if ( !response.ok ) {
        setModalTitle("Error");
        setModalMessage( response.message || genericErrorMessage );
        return;
      } else {
        setModalTitle("Success");
        setModalMessage("User and associated content removed.");
        return;
      }
    })
    .catch( error => {
      setModalTitle("Error");
      setModalMessage(`Error removing user: ${ error }`);
      return;
    })
    .finally(() => {
      setShow(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    })
  };

  return (
    <Layout>
      <div className={styles.adminDash}>
        {
          modalMessage &&
          <Modal
            id="message-modal"
            show={show}
            onHide={() => setShow(false)}
            aria-labelledby="error-modal"
            centered
          >
            <Modal.Header>
              <Modal.Title>
                { modalTitle }
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              { modalMessage }
            </Modal.Body>
          </Modal>
        }
        <Row className="content page-heading mb-4">
          <Col>
            <h1 className="h3 text-gray-800">
              <FontAwesomeIcon icon={ faCog } size="sm" className="text-gray-500 d-none d-sm-inline" aria-hidden="true" />
              {' '}Administrator Dashboard
            </h1>
          </Col>
        </Row>
        <Row className="content">
          <Col>
            User Count: { appUsers.length ? appUsers.length : 0 }
          </Col>
        </Row>
        <Row className="content admin">
          <Col>
            <Table responsive striped hover size="sm">
              <thead>
                <tr>
                  <th className="admin-user-createdat">Created</th>
                  <th className="admin-user-username">Username</th>
                  <th className="admin-user-hasrole">Role</th>
                  <th className="admin-user-actions"><span className="sr-only">Actions</span></th>
                </tr>
              </thead>
              <tbody>
                { appUsers.length &&
                  appUsers.map(function (user) {
                    let userCreated = new Date(user.createdAt);
                    return (
                      <tr key={user._id}>
                        <td>{ `${ userCreated.toDateString() }` }</td>
                        <td>{ user.username }</td>
                        <td>{ user.role }</td>
                        <td>
                          <Button
                            variant="primary"
                            className="ml-auto mr-4"
                            onClick={(e) => resetUserPassword(e, user.username)}
                            title="Reset Password"
                          >
                            <FontAwesomeIcon icon={ faSyncAlt } size="sm" fixedWidth className="text-white-50" />
                          </Button>
                          <ButtonGroup>
                            <Button
                              variant="info"
                              value="admin"
                              disabled={ user.role === 'admin'}
                              onClick={(e) => changeUserRole(e, user._id, 'admin')}
                              title="Elevate to Administrator"
                            >
                              <FontAwesomeIcon icon={ faArrowUp } size="sm" fixedWidth className="text-white-50" />
                            </Button>
                            <Button
                              variant="info"
                              value="user"
                              disabled={ user.role === 'user'}
                              onClick={(e) => changeUserRole(e, user._id, 'user')}
                              title="Downgrade to User"
                              className="mr-4"
                            >
                              <FontAwesomeIcon icon={ faArrowDown } size="sm" fixedWidth className="text-white-50" />
                            </Button>
                          </ButtonGroup>
                          &nbsp;
                          <Button
                            variant="danger"
                            onClick={(e) => deleteUser(e, user._id)}
                            title="Delete User"
                          >
                            <FontAwesomeIcon icon={ faTrash } size="sm" fixedWidth className="text-white-50" />
                          </Button>
                        </td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
    </Layout>
  );
}

export default AdminDash;