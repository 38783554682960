import React from 'react';
import {
  Row,
  Col,
  Table
} from 'react-bootstrap';
/** Custom Components */
import ListCheckin from '../ListCheckin';
/** Styles */
import styles from './styles.module.scss';

const ReportGoal = ( props ) => {
  const {
    title,
    obstacles,
    plan,
    hasTargetDate,
    targetStartDate,
    targetEndDate,
    checkins
  } = props.goal;

  const setReadableDate = ( date ) => {
    if ( date ) {
      let value = new Date(date);
      return value.toLocaleString('en-US', { timeZone: 'America/New_York'}).split(', ')[0];
    } else {
      return '';
    }
  };

  return (
    <Row className={styles.reportGoal + " p4"}>
      <Col>
        <h3>{ title }</h3>
        <div className="mx-4 border-bottom-secondary pb-4 mb-4">
          <h4 className="text-uppercase text-gray-800 mt-4">Plan:</h4>
          <p>{ plan }</p>
          <h4 className="text-uppercase text-gray-800 mt-4">Obstacles:</h4>
          <p>{ obstacles }</p>
          { hasTargetDate && 
            <>
              <h4 className="text-uppercase text-gray-800 mt-4">Target Date:</h4>
              <p>Start: { setReadableDate(targetStartDate)}</p>
              <p>End: { setReadableDate(targetEndDate) }</p>
            </>
          }
          <h4 className="text-uppercase text-gray-800 mt-4">Check Ins:</h4>
          <Table responsive size="sm">
            <thead>
              <tr className="d-none d-md-table-row">
                <th>Date</th>
                <th>Rating</th>
                <th>Reflection</th>
              </tr>
            </thead>
            <tbody>
              {
                checkins.map(function (checkin) {
                  return <ListCheckin key={checkin._id} checkin={checkin} />
                })
              }
            </tbody>
          </Table>
        </div>
      </Col>
    </Row>
  );
};

export default ReportGoal;