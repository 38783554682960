import { createContext, useContext, useState, useMemo } from 'react';

const UserContext = createContext(null);

let initialState = {};

/** *
 * For future use to restrict userContext use.
 */
function useUser() {
  const context = useContext(UserContext);
  if ( !context ) {
    throw new Error(`useUserContext must be used within UserProvider`);
  }
  return context;
}

/** *
 * UserProvider
 * 
 * @params props - props passed to be rendered within context provider
 * @returns object - object representing components, props, and state.
 */
const UserProvider = props => {
  const [state, setState] = useState(initialState);
  const value = useMemo(() => [state, setState], [state]);

  return (
    <UserContext.Provider value={value}>
      {props.children}
    </UserContext.Provider>
  )
}

export { useUser, UserContext, UserProvider };