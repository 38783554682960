/* eslint-disable no-unused-vars */
import React, { useState, useContext, useCallback, useEffect, useReducer } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Card,
  Form,
  Modal,
  Button,
  Table
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faFrown, faMeh, faSmile, faMapMarkerAlt, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
/** Custom Imports */
import Layout from '../../components/Layout';
import Loader from '../../components/Loader';
import ListCheckin from '../../components/ListCheckin';
import { UserContext } from '../../context/UserContext';
/** Styles */
// import styles from './styles.module.scss';

const Checkin = ( props ) => {
  const [ userGoal, setUserGoal ] = useState('');
  const [ checkins, setCheckins ] = useState([]);
  const [ reflection, setReflection ] = useState('');
  const [ checkinRating, setCheckinRating ] = useState(1);
  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const [ error, setError ] = useState('');
  const [ show, toggle ] = useReducer( state => !state, true);
  const [ userContext, setUserContext ] = useContext(UserContext);
  const [ goalStatus, setGoalStatus ] = useState('');
  const [ newStatus, setNewStatus ] = useState('');
  const history = useHistory();
  const { goalId } = useParams('');
  const overallRating = '';
  const genericErrorMessage = "Something went wrong. Please try again.";

  const returnToDash = e => {
    e.preventDefault();
    history.push("/dashboard");
  };

  const formSubmitHandler = e => {
    e.preventDefault();
    setIsSubmitting(true);
    setError("");
    let formData = {
      ...checkinRating && { rating: checkinRating },
      ...reflection && { reflection },
      ...goalId && { goalId },
      ...newStatus && { goalStatus: newStatus }
    }
    fetch(`/api/checkin/`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ userContext.token }`,
      },
      body: JSON.stringify( formData ),
    })
    .then( async response => {
      if ( response.ok || response.status === 200 ) {
        setCheckinRating('');
        setReflection('');
        return fetchUserGoal();
      } else {
        toggle();
        if ( response.status === 500 || response.status === 501 ) {
          const data = await response.json();
          if ( data.message ) setError( data.message || genericErrorMessage );
          return;
        } else {
          setError( genericErrorMessage );
          return;
        }
      }
    })
    .catch( err => {
      toggle();
      setError( err || genericErrorMessage );
    })
    .finally(() => {
      setIsSubmitting(false);
    });
  };

  const fetchUserGoal = useCallback(() => {
    setIsSubmitting(true);
      if ( userContext ) {
        fetch(`/api/goal/${ goalId }`, {
          method: 'GET',
          credentials: 'include',
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ userContext.token }`,
        },
        })
        .then( response =>
          response.json()
        )
        .then(data => {
          setUserGoal( data.goal );
          setCheckins( data.goal.checkins );
          setGoalStatus( data.goal.status );
        })
        .catch( error => {
          toggle();
          setError( error.message || genericErrorMessage );
        })
        .finally(() => {
          setIsSubmitting(false);
        });
      } else { return; }
  },[userContext, goalId]);

  useEffect(() => {
    fetchUserGoal();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchUserGoal]);

  const setReadableStatus = ( value ) => {
    if ( value === 'inprogress') return 'In Progress';
    if ( value === 'hold') return 'On Hold';
    if ( value === 'complete') return 'Complete';
    return 'Not Set';
  };

  return (
    <Layout>
      <div className="goals">
        {
          error &&
          <Modal
            show={ show }
            onHide={ toggle }
            aria-labelledby="error-modal"
            centered
          >
            <Modal.Header>
              <Modal.Title>
                Error
              </Modal.Title>
              <Modal.Body>
                { error }
              </Modal.Body>
            </Modal.Header>
          </Modal>
        }
        { isSubmitting ? (
          <Loader />
        ) : (
          <>
            <Row className="content page-heading mb-4 pb-4">
              <Col sm={12} md={7}>
                <h1 className="h3 text-gray-800">
                  <FontAwesomeIcon icon={ faMapMarkerAlt } size="sm" className="text-gray-500 d-none d-sm-inline" aria-hidden="true" />
                  {' '}Goal Details
                </h1>
              </Col>
              <Col sm={12} md={5} className="col-12 col-md-5 d-flex justify-content-end">
                <a href="#update" className="btn btn-success shadow-sm flex-fill mr-2 flex-md-grow-0" title="Check In">
                  <FontAwesomeIcon icon={ faPencilAlt } size="sm" className="sm text-white-50" aria-hidden="true" />
                  {' '}Add Check-In
                </a>
                <a href="/dashboard" className="btn btn-primary shadow-sm flex-fill flex-md-grow-0" title="Back to Dashboard">
                  <FontAwesomeIcon icon={ faArrowLeft } size="sm" className="sm text-white-50" aria-hidden="true" />
                  {' '}Go to Dashboard
                </a>
              </Col>
            </Row>
            <Row className="content">
              <Col>
                <Card id="goal-info" className="border-bottom-primary shadow mb-4">
                  <Card.Header className="bg-primary">
                    <h6 className="m-0 font-weight-bold text-uppercase text-white">Your Goal</h6>
                  </Card.Header>
                  <Card.Body className="no-gutters m-0 p-0">
                    <Row className="card-row title">
                      <Col>
                        <h3>{ userGoal.title }</h3>
                      </Col>
                    </Row>
                    <Row className="card-row details">
                        <Col className="status">
                          <h4>Status:</h4>
                          <p className="h3 text-capitalize p-0 mb-0">
                            { setReadableStatus(userGoal?.status) }
                          </p>                                                
                        </Col>
                        <Col className="target">
                            <h4 >Target:</h4>
                            <p className="h3 text-capitalize font-weight-normal mb-0">
                              { userGoal?.endDate || 'Ongoing'}
                            </p>
                        </Col>
                    </Row>
                    <Row className="card-row d-flex justify-content-around notes">
                      <Col sm={12} md={5} className="pr-md-4 obstacles mb-4 mb-md-0">
                          <h4>Obstacles:</h4>
                          <p>{ userGoal?.obstacles }</p>
                      </Col>
                        <Col sm={12} md={5} className="offset-md-1 pl-md-4 plan">
                          <h4>Plan:</h4>
                          <p>{ userGoal?.plan }</p>
                        </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="content">
              <Col>
                <Card className="border-bottom-success shadow my-4" id="update">
                  <Card.Header className="bg-success py-3">
                    <h6 className="m-0 font-weight-bold text-uppercase text-white">Add Goal Check-In</h6>
                  </Card.Header>
                  <Card.Body>
                    <Card.Text>
                      <p className="lead mb-4">
                        <strong>Regular self-reflection is an integral part of working toward your goals.</strong>
                        {' '}Take a moment to think about your goal, then complete the check-in form to track your progress and
                        {' '} adapt your plan as needed.
                      </p>
                    </Card.Text>
                    <Form onSubmit={formSubmitHandler}>
                      <Row className="status form-row">
                        <Form.Group>
                          <Form.Label className="mb-4">What is the status of your goal?</Form.Label>
                          <Form.Select
                            aria-label="Goal status"
                            id="goalStatus"
                            onChange={e => setNewStatus(e.target.value)}
                          >
                            <option
                              value="inprogress"
                              selected={newStatus === "" ? (goalStatus === "inprogress" ? true : false) : (newStatus === "inprogress" ? true : false)}
                            >
                              In Progress
                            </option>
                            <option
                              value="hold"
                              selected={newStatus === "" ? (goalStatus === "hold" ? true : false) : (newStatus === "hold" ? true : false)}
                            >
                              On Hold
                            </option>
                            <option
                              value="complete"
                              selected={newStatus === "" ? (goalStatus === "complete" ? true : false) : (newStatus === "complete" ? true : false)}
                            >
                              Complete
                            </option>
                          </Form.Select>
                        </Form.Group>
                      </Row>
                      <Row className="rating form-row">
                        <Form.Label className="mb-4">
                          How would you rate your work on this goal since your last check-in?
                        </Form.Label>
                        <Col>
                          <Form.Group className="btn-group row no-gutters w-100" controlId="checkin-rating-group" >
                            {/* Needs Improvement */}
                            <Form.Check 
                              type="radio"
                              inline
                              className="btn btn-danger col-4 mx-0 d-flex align-self-stretch"
                            >
                              <Form.Check.Label className="form-check-label d-flex flex-column align-self-stretch align-items-center justify-content-between">
                                <FontAwesomeIcon icon={ faFrown } size="3x" className="mx-auto" />
                                <p className="my-4">
                                  Needs Improve&shy;ment
                                </p>
                                <Form.Check.Input
                                  type="radio"
                                  value="1"
                                  name="checkin-rating"
                                  id="checkin-rating"
                                  onChange={e => setCheckinRating(e.target.value)}
                                  className="form-check-input mx-auto mt-auto"
                                />
                              </Form.Check.Label>
                            </Form.Check>
                            {/* Neutral */}
                            <Form.Check
                              type="radio"
                              inline
                              className="btn btn-warning col-4 mx-0 d-flex align-self-stretch"
                            >
                              <Form.Check.Label className="form-check-label d-flex flex-column align-self-stretch align-items-center justify-content-between">
                                <FontAwesomeIcon icon={ faMeh } size="3x" className="mx-auto" />
                                <p className="my-4">
                                  OK / Neutral
                                </p>
                                <Form.Check.Input
                                  type="radio"
                                  value="2"
                                  name="checkin-rating"
                                  id="checkin-rating"
                                  onChange={e => setCheckinRating(e.target.value)}
                                  className="form-check-input mx-auto mt-auto"
                                />
                              </Form.Check.Label>
                            </Form.Check>
                            {/* Good */}
                            <Form.Check
                              type="radio"
                              inline
                              className="btn btn-success col-4 mx-0 d-flex align-self-stretch"
                            >
                              <Form.Check.Label className="form-check-label d-flex flex-column align-self-stretch align-items-center justify-content-between">
                                <FontAwesomeIcon icon={ faSmile } size="3x" className="mx-auto" />
                                <p className="my-4">
                                  Good
                                </p>
                                <Form.Check.Input
                                  type="radio"
                                  value="3"
                                  name="checkin-rating"
                                  id="checkin-rating"
                                  onChange={e => setCheckinRating(e.target.value)}
                                  className="form-check-input mx-auto mt-auto"
                                />
                              </Form.Check.Label>
                            </Form.Check>
                          </Form.Group>                            
                        </Col>
                      </Row>
                      <Row className="form-row my-4">
                        <Form.Group className="reflection">
                          <Form.Label>How are you doing?</Form.Label>
                          <Form.Text className="mb-4">
                            Take a moment to think about your work on your goal since your last check-in. Reflect on your progress.
                            {' '}What went well? What could have gone better? Whats your plan until your next check-in?
                          </Form.Text>
                          <Form.Control
                            as="textarea"
                            rows="6"
                            value={reflection}
                            onChange={e => setReflection (e.target.value)}
                          />
                        </Form.Group>
                      </Row>
                      <Row>
                        <Col>
                          <Row className="form-row mb-0">
                            <Col className="d-flex justify-content-end">
                              <Button
                                variant="primary"
                                className="ml-auto mr-3 btn-lg flex-fill flex-md-grow-0"
                                type="submit"
                                disabled={isSubmitting}
                              >
                                { `${ isSubmitting ? "Checking In" : "Add Check In" }`}
                              </Button>
                              <Button
                                variant="danger"
                                onClick={returnToDash}
                                className="btn-lg flex-fill flex-md-grow-0"
                              >
                                Cancel
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="content my-4 pb-4">
              <Col>
                <Card id="history" className="border-bottom-secondary shadow">
                  <Card.Header className="bg-secondary py-3">
                    <h6 className="m-0 font-weight-bold text-uppercase text-white">
                      Check-in History
                    </h6>
                  </Card.Header>
                  <Card.Body>
                    <Table responsive hover size="sm">
                      <thead>
                        <tr className="d-none d-md-table-row">
                          <th className="history-date" scope="col">Date</th>
                          <th className="history-rating" scope="col"><span className="sr-only">Rating</span></th>
                          <th className="history-reflection" scope="col">Reflection</th>
                        </tr>
                      </thead>
                      <tbody>
                        { 
                          checkins.map(function (checkin) {
                            return <ListCheckin key={checkin._id} checkin={checkin} />
                          })
                        }
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </div>
    </Layout>
  );
};

export default Checkin;