import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
/** Components */
import SideBar from "../SideBar";
import NavBar from "../Nav";
import Footer from "../Footer";
/** Styles */
import styles from "./styles.module.scss";
// import { SettingsContext } from 'context/SettingsContext';

/**
 * Displays base presentational components and information passed in as props
 * @param props
 */
const Layout = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);
  // const [ userSettings, setUserSettings ] = useContext(SettingsContext);

  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const handleToggleSidebar = (value) => {
    setToggled(value);
    setCollapsed(false);
  };

  return (
    <>
      <Container className={styles.cmfrMain} fluid>
        <Row className="flex-nowrap no-gutters">
          <Col className={styles.layoutSidebar + " col-auto"}>
            <SideBar
              collapsed={collapsed}
              toggled={toggled}
              handleCollapse={handleCollapse}
              handleToggleSidebar={handleToggleSidebar}
            />
          </Col>
          <Col className="col">
            <header>
              <NavBar
                handleCollapse={handleCollapse}
                handleToggleSidebar={handleToggleSidebar}
              />
            </header>
            <div className="content">{props.children}</div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Layout;
