/* eslint-disable no-unused-vars */
import React, { useState, useContext, useCallback, useEffect } from "react";
import { Form, Col, Row, Button, Card, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSmile } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
/** Custom Imports */
import Layout from "../../components/Layout";
/** Context */
import { UserContext } from "../../context/UserContext";
/** Styles */
// import styles from './styles.module.scss';

const Profile = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [prevFName, setPrevFName] = useState("");
  const [lastName, setLastName] = useState("");
  const [prevLName, setPrevLName] = useState("");
  const [email, setEmail] = useState("");
  const [prevEmail, setPrevEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [prevNotificationType, setPrevNotificationType] = useState("");
  const [userContext, setUserContext] = useContext(UserContext);

  const fetchUserDetails = useCallback(() => {
    fetch("/api/user/profile", {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userContext.token}`,
      },
    }).then(async (response) => {
      if (response.ok) {
        const data = await response.json();
        setPrevEmail(data.username);
        setPrevFName(data.firstName);
        setPrevLName(data.lastName);
        setPrevNotificationType(data.notificationType);
        setUserContext((oldValues) => {
          return { ...oldValues, details: data };
        });
      } else {
        if (response.status === 401) {
        } else {
          setUserContext((oldValues) => {
            return { ...oldValues, details: null };
          });
        }
      }
    });
  }, [setUserContext, userContext.token]);

  useEffect(() => {
    if (!userContext.details) {
      fetchUserDetails();
    }
  }, [userContext.details, fetchUserDetails]);

  const formSubmitHandler = (e) => {
    console.log("lol...");

    e.preventDefault();
    setIsSubmitting(true);
    setError("");
    if (password && !(password === confirmPassword)) {
      setIsSubmitting(false);
      setError("Password and Confirm Password must match.");
      return;
    }

    /** Construct formData to send to endpoint only if changed */
    let formData = {
      ...(firstName && { firstName: firstName }),
      ...(lastName && { lastName: lastName }),
      ...(email && { username: email }),
      ...(notificationType && { notificationType: notificationType }),
    };
    if (password && confirmPassword && password === confirmPassword) {
      formData.password = password;
    } else if (password && confirmPassword && password !== confirmPassword) {
      setShow(true);
      setError("Password fields do not match.");
    } else {
    }

    const genericErrorMsg = "Something went wrong updating your account.";
    fetch(`/api/user/${userContext.details._id}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userContext.token}`,
      },
      body: JSON.stringify(formData),
    })
      .then(async (response) => {
        setIsSubmitting(false);
        if (!response.ok) {
          setShow(true);
          if (response.status === 401) {
            setError("Invalid email or password");
          } else {
            setError(genericErrorMsg);
          }
        } else {
          setShow(false);
          const data = await response.json();
          setUserContext((oldValues) => {
            return { ...oldValues, token: data.token };
          });
          window.location.reload();
        }
      })
      .catch((error) => {
        setIsSubmitting(false);
        setError(genericErrorMsg);
      });
  };

  return (
    <Layout>
      <div className="profile">
        <Row className="content hero">
          <Col sm={12}>
            <FontAwesomeIcon
              icon={faSmile}
              size="5x"
              className="text-primary float-right rotate-n-15"
              aria-hidden="true"
            />
            <h1 className="mb-4">
              <span className="display-4 text-gray-600 text-uppercase">
                Hello,
              </span>
              <br />
              <span className="display-2 font-weight-bold text-primary">
                {prevFName}!
              </span>
            </h1>
            <p className="lead">
              To update your information, make your changes in the form below
              and click save.
            </p>
          </Col>
        </Row>
        <Row className="content mb-4">
          <Col>
            <Card>
              <Card.Header className="bg-primary text-white">
                <h2 class="h5 text-uppercase mb-0">Account Details</h2>
              </Card.Header>
              <Card.Body>
                <Form onSubmit={formSubmitHandler}>
                  <Row className="form-row mb-4">
                    <Col sm={12} md={6}>
                      <Form.Group className="form-group">
                        <Form.Label>First name</Form.Label>
                        <Form.Control
                          id="firstName"
                          value={firstName}
                          placeholder={prevFName}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={12} md={6}>
                      <Form.Group className="form-group">
                        <Form.Label>Last name</Form.Label>
                        <Form.Control
                          id="lastName"
                          value={lastName}
                          placeholder={prevLName}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="form-row mb-4">
                    <Col sm={12}>
                      <Form.Group className="form-group">
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control
                          id="email"
                          type="email"
                          value={email}
                          placeholder={prevEmail}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="form-row mb-4">
                    <Col sm={12} md={6}>
                      <Form.Group className="form-group">
                        <Form.Label>New Password</Form.Label>
                        <Form.Control
                          id="password"
                          type="password"
                          min="6"
                          onChange={(e) => setPassword(e.target.value)}
                          size="lg"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={12} md={6}>
                      <Form.Group className="form-group">
                        <Form.Label>Confirm New Password</Form.Label>
                        <Form.Control
                          id="confirmPassword"
                          type="password"
                          min="6"
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          size="lg"
                        />
                      </Form.Group>
                    </Col>
                    <Form.Text>
                      Leave blank to keep exisiting password. Password must be a
                      minimum of 6 characters.
                    </Form.Text>
                  </Row>
                  <Row className="form-row">
                    <Col sm={12}>
                      <Form.Group className="form-group">
                        <Form.Label class="d-block mb-2">
                          How often would you like to be reminded to check in?
                        </Form.Label>
                        <Form.Check
                          inline
                          label="None"
                          name="notification"
                          type="radio"
                          value="none"
                          className="d-inline-flex align-items-center form-check form-check-inlnine"
                          checked={
                            notificationType === ""
                              ? prevNotificationType === "none"
                                ? true
                                : false
                              : notificationType === "none"
                              ? true
                              : false
                          }
                          onChange={(e) => setNotificationType(e.target.value)}
                        />
                        <Form.Check
                          inline
                          label="Weekly"
                          name="notification"
                          type="radio"
                          value="weekly"
                          className="d-inline-flex align-items-center form-check form-check-inlnine"
                          checked={
                            notificationType === ""
                              ? prevNotificationType === "weekly"
                                ? true
                                : false
                              : notificationType === "weekly"
                              ? true
                              : false
                          }
                          onChange={(e) => setNotificationType(e.target.value)}
                        />
                        <Form.Check
                          inline
                          label="Monthly"
                          name="notification"
                          type="radio"
                          value="monthly"
                          className="d-inline-flex align-items-center form-check form-check-inlnine"
                          checked={
                            notificationType === ""
                              ? prevNotificationType === "monthly"
                                ? true
                                : false
                              : notificationType === "monthly"
                              ? true
                              : false
                          }
                          onChange={(e) => setNotificationType(e.target.value)}
                        />
                        <Form.Check
                          inline
                          label="Twice Monthly"
                          name="notification"
                          type="radio"
                          value="twicemonthly"
                          className="d-inline-flex align-items-center form-check form-check-inlnine"
                          checked={
                            notificationType === ""
                              ? prevNotificationType === "twicemonthly"
                                ? true
                                : false
                              : notificationType === "twicemonthly"
                              ? true
                              : false
                          }
                          onChange={(e) => setNotificationType(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="d-flex justify-content-end">
                      <Button
                        variant="primary"
                        className="ml-auto btn-lg flex-fill flex-md-grow-0"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {`${isSubmitting ? "Saving" : "Save"}`}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default Profile;
